<template>
    <div class="management-modal-mask">
        <div class="management-modal-content-container">
            <div class="management-modal-header-container">
                <h1 class="management-modal-header-title">Adicionar Produto a um Grupo</h1>
                <span @click="close" class="material-icons management-modal-closebtn-icon">cancel</span>
            </div>
            <div class="management-modal-body">
                <h1 class="add-product-body-title">Lista de grupos</h1>
                <div class="add-product-table-container">
                    <div :class="{'add-product-selected':value.checked || value.produto}" class="add-product-select-button" @click="productClick(value)" v-for="(value, idx) in groups" :key="value.id">
                        {{ value.descricao }}
                        <span v-if="value.produto && !value.remove"class="material-icons-outlined">done</span>
                        <span v-else-if="value.checked" class="material-icons-outlined">radio_button_checked</span>
                        <span v-else class="material-icons-outlined">radio_button_unchecked</span>
                    </div>
                </div>
                <StandardButton class="add-product-button" text="Salvar" iconName="save" :action="addProductToGroup"/>
            </div>

        </div>

        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import ProductGroupService from '@/services/v3/management/ProductGroupService.js'

export default {
    name: 'AddToGroupModal',
    props: ['product', 'close', 'update'],
    components: {
        StandardButton,
    },
    data(){
        return{
            isLoading: true,
            service: new ProductGroupService(),
            groups: [],
            selectedGroup: undefined
        }
    },
    methods:{
        loadPage() {
            this.isLoading = true
            this.service.loadProductGroups(this.product.pro_id).then(resp => {
                this.groups = resp.data
                this.isLoading = false
            })
        },
        productClick(value){
            if (value.produto) value.remove = !value.remove
            else value.checked=!value.checked
            this.$forceUpdate()
        },
        async addProductToGroup(){
            let localList = []
            let groupsList = this.groups.filter(element => element.checked)
            let removeList = this.groups.filter(element => element.remove)
            if (this.product instanceof Array) localList = this.product
            else localList = [this.product]
            this.isLoading = true
            for (let g = 0; g < groupsList.length; g++) {
                const selectedGroup = groupsList[g];
                for (let index = 0; index < localList.length; index++) {
                    const element = localList[index];
                    await this.service.addProduct(element.pro_id, selectedGroup.id)
                }
            }
            for (let index = 0; index < removeList.length; index++) {
                const element = removeList[index];
                console.log(element);
                await this.service.deleteProduct(element.produto.id)
            }
            this.isLoading = false
            this.update()
            this.close()
        }
    },
    created() {
        this.loadPage()
    }
}
</script>

<style scoped lang="scss">
.management-modal-mask {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.management-modal-content-container {
    background-color: white;
    width: 50vw;
    border-radius: 12px;
}

.management-modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.management-modal-header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2em;
}

.management-modal-closebtn-icon {
    color: #ffffff;
    font-size: 3em;
    cursor: pointer;
}

.management-modal-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
}

.management-modal-body {
    padding: 2vh 2vw;
}
.manage-categories__input {
    width: 16vw;
    height: 6vh;
    padding: 0px 10px;
    border: 1px solid #c2c2c3;
    border-radius: 5.23px;
    font-weight: 400;
    font-size: 1.1em;
    color: #A1A1A1;
}
.management-modal-body-title{
    color: #898989;
    font-weight: 500;
    font-size: 1.5em;
}
.add-product-body-title{
    font-size: 2em;
    color: #898989;
    margin-bottom: 2vh;
}
.add-product-table-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2vh 1vw;
}
.add-product-select-button{
    border: 1px solid #898989;
    color: #898989;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 0.5vh 1vw;
    cursor: pointer;
    font-size: 1.2em;
}
.add-product-selected{
    color: var(--primary-color);
    border-color: var(--primary-color);
}
.add-product-button{
    width: 45%;
    margin-left: auto;
    margin-top: 4vh;
}
</style>