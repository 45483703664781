<style lang="scss" scoped>

.logo {
  width: 10rem;
}

.btn_send {
  padding: 10px 15px;
  background: #f39c12;
  border: 1px solid #f39c12;
  color: white;
  border-radius: 5px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.button_monitor {
  padding: 10px 15px;
  background: transparent;
  border: 1px solid #e8e9ec;
  border-radius: 5px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.button_monitor i {
  color: #ec7f3b;
  font-size: 24px;
  margin-right: 10px;
}
.table {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0;
  thead {
    tr {
      th {
        text-transform: none;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 0.9rem;
        font-weight: 400;
        a {
          font-weight: 700;
        }
        span {
          border-radius: 25px;
          padding: 0 0 !important;
          font-size: 11px;
          font-weight: 700;
          height: 16px;
          &.em-digitacao {
            background-color: rgba(105, 228, 166, 0.2);
          }
          &.nao-digitada {
            background-color: rgba(246, 180, 0, 0.2);
          }
          &.pedido-gerado {
            background-color: rgba(27, 41, 168, 0.2);
          }
          &.cancelado {
            background-color: rgba(201, 17, 17, 0.2);
          }
        }
        .select {
          width: 100px;
          select {
            width: 100px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="mobile-list__header-container">
        <span class="mobile-list__header-title">
          Consulta de Itens Lista Mobile
        </span>
        <span @click="close" class="material-icons mobile-list__closebtn-icon">
          cancel
        </span>
      </div>
      <div class="mobile-list__top-content">
        <div class="mobile-list__top-content__left">
          <!-- <span>Produtos da Lista: {{nameListMobile.join(",")}}</span> -->
        </div>
        <div class="mobile-list__top-content__right">
          <StandardButton class="standard-button" :action="close" :iconName="'arrow_back'" :text="'Voltar'"/>
          <StandardButton class="standard-button" style="margin-left: 10px;" :action="print" :iconName="'print'" :text="'Imprimir'"/>
        </div>
      </div>
      <div class="quotation-products-container">
        <span class="quotation-products-title">Produtos da Lista: {{ listMobile }}</span>
      </div>
      <cc-loader v-if="loading" class="loader" style="margin: 2em;" />
      <div v-if="!loading" class="overflow-container">
        <div class="modal-table-container">
          <div class="modal-table-header">
            <div class="modal-table-column description-width">Descrição</div>
            <div class="modal-table-column ean-width">EAN</div>
            <div class="modal-table-column package-width">Embalagem</div>
            <div class="modal-table-column category-width">Categoria</div>
            <div class="modal-table-column quantity-width">Quantidade</div>
            <div class="modal-table-column stock-width">Estoque</div>
            <div class="modal-table-column list-width">Lista</div>
            <div class="modal-table-column store-width">Loja</div>
            <div class="modal-table-column user-width">Usuario</div>
          </div>
          <div v-for="(item, idx) in products"
               :key="item.produto_id"
               :class="getSpecialBackground(idx)">

            <!-- desktop -->
            <div class="modal-table-row desktop">
              <div class="modal-table-column description-width">{{ item.pro_descricao }}</div>
              <div class="modal-table-column ean-width">{{ item.pro_ean }}</div>
              <div class="modal-table-column package-width">{{ item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem }}</div>
              <div class="modal-table-column category-width">{{ item.cat_descricao }}</div>
              <div class="modal-table-column quantity-width">{{ item.quantidade }}</div>
              <div class="modal-table-column stock-width">{{ item.estoque || 0 }}</div>
              <div class="modal-table-column list-width">{{ item.lista_descricao }}</div>
              <div class="modal-table-column store-width">{{ item.loj_descricao }}</div>
              <div class="modal-table-column user-width">{{ item.usu_nome }}</div>
            </div>

            <!-- mobile -->
            <div class="mobile-table-row">
              <h5 class="mobile-row-title">{{ item.pro_descricao }}</h5>
              <div v-on:click="() => {item.isExpand = !item.isExpand; $forceUpdate()}" class="mobile-row-title" style="margin-top: -1vh; display: flex; align-items: center;">
                <span style="color: var(--primary-color);" v-if="!item.isExpand" class="material-icons-outlined">expand_more</span>
                <span style="color: var(--primary-color);" v-else class="material-icons-outlined">expand_less</span>
                EAN: {{ item.pro_ean }}
              </div>
              <div class="mobile-table-colums">
                <div class="mobile-colum-width">
                  <h5 class="mobile-row-title" style="font-weight: normal;">Quantidade</h5>
                  {{ item.quantidade }}
                </div>
                <div class="mobile-colum-width mobile-center">
                  <h5 class="mobile-row-title" style="font-weight: normal;">Embalagem</h5>
                  {{ item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem }}
                </div>

                <div class="mobile-colum-width mobile-end">
                  <h5 class="mobile-row-title" style="font-weight: normal;">Estoque</h5>
                  {{ item.estoque || 0 }}
                </div>
              </div>

              <div class="mobile-table-colums" v-if="item.isExpand">
                <div class="mobile-colum-width ">
                  <h5 class="mobile-row-title" style="font-weight: normal;">Categoria</h5>
                  {{ item.cat_descricao }}
                </div>
                <div class="mobile-colum-width mobile-center">
                  <h5 class="mobile-row-title" style="font-weight: normal;">Lista</h5>
                  {{ item.lista_descricao }}
                </div>
                <div class="mobile-colum-width mobile-center" style="width: 50%;">
                  <h5 class="mobile-row-title" style="font-weight: normal;">Loja</h5>
                  {{ item.loj_descricao }}
                </div>
                <div class="mobile-colum-width mobile-end">
                  <h5 class="mobile-row-title" style="font-weight: normal;">Usuario</h5>
                  {{ item.usu_nome }}
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- apenas impressão -->
    <transition name="modal">
    <div hidden class="modal-mask">
      <div class="modal-wrapper" id="products-modal">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div class="row" style="width: 100%;">
                <div style="display: flex; gap: 40px; align-items: center;">
                  <img class="logo" src="/images/logotipo.png"/>
                  <div>Produtos da Lista: {{listMobile}}</div>
                </div>
              </div>
            </slot>
          </div>

          <div class="modal-body" >
            <div class="row" id="modalBodyPrint">
              <div class="table">
                <div class="print-table-row">
                    <div class="print-table-desc">Descrição</div>
                    <div class="print-table-ean">EAN</div>
                    <div class="print-table-eamb">Embalagem</div>
                    <div class="print-table-cat">Categoria</div>
                    <div class="print-table-quan">Qtd.</div>
                    <div class="print-table-est">Estq.</div>
                    <div class="print-table-list">Lista</div>
                    <div class="print-table-loj">Loja</div>
                    <div class="print-table-usse">Usuário</div>
                </div>
                <div class="print-table-row" v-for="item in products" v-bind:key="item.produto_id">
                    <div class="print-table-desc">{{ item.pro_descricao}}</div>
                    <div class="print-table-ean">{{ item.pro_ean}}</div>
                    <div class="print-table-eamb">{{item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}</div>
                    <div class="print-table-cat">{{item.cat_descricao}}</div>
                    <div class="print-table-quan">{{item.quantidade}}</div>
                    <div class="print-table-est">{{item.estoque || 0}}</div>
                    <div class="print-table-list">{{item.lista_descricao}}</div>
                    <div class="print-table-loj">{{item.loj_descricao}}</div>
                    <div class="print-table-usse">{{item.usu_nome}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  </div>
  <!-- <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" id="products-modal">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div class="row" style="width: 100%;">
                <div class="col-3">
					<img class="logo" src="/images/logotipo.png"/>
				</div>
                <div class="col-sm-7" style="text-align: left;color: gray;">
                  <h4
                    style="padding-bottom: 9px;padding-right: 23px;text-align: left;color: gray;font-family:monospace;font-size: 25px;"
                  >Produtos da Lista: {{nameListMobile.join(",")}}</h4>
                </div>

                <div class="col-sm-2 not-print" style="text-align: left;color: gray;">
                  <button v-on:click="print()" class="button_monitor">
                    <span class="icon-icon_print" style="margin-right: 7px;"></span>
                    Imprimir
                  </button>
                </div>
              </div>
            </slot>
          </div>

          <div class="modal-body" >
            <div class="row" id="modalBodyPrint">
              <div class="col-sm-10 only-print" style="text-align: left;color: gray;">
                  <h4
                    style="padding-right: 23px;text-align: left;color: gray;"
                  >Produtos da Cotação: {{listMobile}}</h4>
                </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Descrição</th>
                    <th>EAN</th>
                    <th>Embalagem</th>
                    <th>Categoria</th>
                    <th>Quantidade</th>
                    <th>Estoque</th>
                    <th>Lista</th>
                    <th>Loja</th>
                    <th>Usuário</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in products" v-bind:key="item.produto_id">
                    <td>{{ item.pro_descricao}}</td>
                    <td>{{ item.pro_ean}}</td>
                    <td>{{item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}</td>
                    <td>{{item.cat_descricao}}</td>
                    <td>{{item.quantidade}}</td>
                    <td>{{item.estoque || 0}}</td>
                    <td>{{item.lista_descricao}}</td>
                    <td>{{item.loj_descricao}}</td>
                    <td>{{item.usu_nome}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer not-print">
            <slot name="footer">
              <button class="btn_close_modal" type="button" @click="close()">Cancelar</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition> -->
</template>

<script>
import QuotationService from "../../../services/QuotationService";
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";

export default {
  props: ["listMobile"],
  components: {
    StandardButton,
  },
  data() {
    return {
      products: [],
      loading: false,
      listMobileItem: [],
    };
  },
  methods: {
    getSpecialBackground (index) {
      if (index % 2 != 0) return 'page-table-line-special'
    },
    close() {
      this.$emit("close");
	  },
    getProducts() {
      this.loading = true
      var quotationService = new QuotationService();
      quotationService
        .getProductsListMobile(this.listMobile)
        .then(response => response.data)
        .then(data => {
          this.products = data.produtos;
          this.loading = false
        })
        .catch(error => {
          ErrorHandlerService.handle(error, this.$store);
          this.loading = false
        });
    },
    print() {
      setTimeout(() => {

		var conteudoModal = window.document.getElementById("products-modal").innerHTML;

		var conteudoImpressao =
			"<html>" +
			"<head>" +
			'<link rel="stylesheet" href="/css/app.css">' +
			'<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic">' +
			"<title>" +
			"</title>" +
            "<style>" +
            ".logo {width: 10rem;}" +
            ".not-print {display: none}" +
            ".table{margin-top: 4vh; border: 1px solid gray}" +
            ".print-table-row{border-bottom: 1px solid gray; display: flex; font-size: 1.3vw; text-transform: lowercase; padding: 0.5vh 1vw; }" +
            ".print-table-desc{flex: 1}" +
            ".print-table-ean{width: 11%}" +
            ".print-table-eamb{width: 13%}" +
            ".print-table-cat{width: 11%}" +
            ".print-table-quan{width: 6%}" +
            ".print-table-est{width: 6%}" +
            ".print-table-list{width: 7%}" +
            ".print-table-loj{width: 11%}" +
            ".print-table-usse{width: 11%}" +
            "</style>" +
			"</head>" +
			"<body>" +
			conteudoModal +
			"</body>";

		let name = '_blank';
		let specs = ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'];
		let replace = true;

		specs = !!specs.length ? specs.join(',') : '';

        var telaImpressao = window.open("", '_blank', ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'], true);

		telaImpressao.document.write(conteudoImpressao);

		setTimeout(() => {
            telaImpressao.document.close();
            telaImpressao.focus();
            telaImpressao.print();
            telaImpressao.close();
          }, 1500);

        this.imprimindo = false;
	  }, 500);
	}
  },

  mounted() {
    this.listMobile
    this.getProducts();
  }
};
</script>
<style lang="scss" scoped>
@import '@/sass/commons/_variables';

/* .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.control-label {
  color: $secondary-color;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
input {
  border-radius: 5px !important;
  margin-bottom: 5px;
}
.modal-container {
  width: 1100px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  overflow: auto;
  margin: 10px 0;
  max-height: 600px;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: rgba(243, 133, 5, 0.5);
}

.subtitle {
  color: #314b5f;
}
.btn_close_modal {
  padding: 10px 15px;
  background: transparent;
  border: 1px solid #f39c12;
  color: gray;
  border-radius: 5px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dropzone.dz-clickable {
  border-radius: 8px;
} */

.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}
.modal-content-container {
  background-color: white;
  width: 100vw;
  border-radius: 12px;
}
.mobile-list__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.mobile-list__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.5em;
}
.mobile-list__closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.mobile-table-row{
  display: none;
}
.mobile-list__top-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 2em;
}
.mobile-list__top-content__left {}
.mobile-list__top-content__right {
  display: flex;
}
.overflow-container {
  overflow-x: auto;
  margin: 1em 0em;
}
.modal-table-container {
  max-height: 50vh;
  margin: 0em 2em;
  overflow-y: auto;
}
.modal-table-header {
  display: flex;
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  padding: 1vh 0;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 17px;
  color: #605F5F;
  margin-bottom: 1vh;
}
.modal-table-column {
  padding-left: 0.5vw;
}
.modal-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1em;
  color: #605F5F;
  margin-bottom: 1vh;
  align-items: center;
}
.description-width {
  width: 34%;
}
.ean-width {
  width: 11%;
}
.package-width {
  width: 11%;
}
.category-width {
  width: 11%;
}
.quantity-width {
  width: 8%;
}
.stock-width {
  width: 8%;
}
.list-width {
  width: 6%;
}
.store-width {
  width: 6%;
}
.user-width {
  width: 8%;
}
.quotation-products-container {
  margin: 1em 2em;
}
.quotation-products-title {
  font-size: 2em;
  line-height: 27px;
  font-weight: 400;
  color: #606060;
}
.page-table-line-special {
  background: #F7F7F7;
}

@media only screen and (max-width: 850px) {
  .modal-mask{font-size: 2.5vw;}
  .mobile-table-row{
    display: block;
    font-size: 1.3em;
    padding: 2vh 2vw;
  }
  .mobile-row-title{
    font-weight: bold;
    font-size: 1.2em;
    color: #605F5F;
  }
  .modal-content-container{
    width: 115vw;
    height: 110vh;
  }
  .overflow-container {}
  .modal-table-container {}
  .mobile-list__header-title {
    font-size: 2.5em;
  }
  .modal-table-header {
    font-size: 1em;
    display: none;
  }
  .modal-table-row {
    font-size: 1.8em;
  }
  .quotation-products-title {
    font-size: 3em;
  }
  .standard-button {
    font-size: 1.5em;
    width: 45%;
  }
  .mobile-list__top-content__right{
    width: 100%;
    justify-content: space-between;
  }
  .mobile-table-colums{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .mobile-colum-width{
    width: 30%;
    color: #605F5F;
  }
  .modal-table-container{
    max-height: 75vh;
  }
  .mobile-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .mobile-end{
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .desktop{display: none;}
}

</style>
