<template>
    <div>
        <div class="page-add-buttons">
            <div v-if="can('PRODUCT', 'UPDATE')" class="page-add-button-containear">
                <Button title="Adicionar múltiplos produtos a um grupo" :text="'Adicionar ao grupo'" :iconName="'add'"
                    :action="addAllProducts" />
            </div>
            <div v-if="can('PRODUCT', 'UPDATE')" class="page-add-button-containear">
                <Button title="Gerenciar grupo de produtos" :text="'Grupo de Produtos'" :iconName="'group_work'"
                    :action="() => showManagementProducts = true" />
            </div>
            <div v-if="can('PRODUCT', 'UPDATE')" class="page-add-button-containear">
                <Button title="Adicionar/editar categorias" :text="'Categorias'" :iconName="'local_offer'" :action="() => {
                this.manage_categories = true;
                this.showClientCategoriesModal = true;
            }
                " />
            </div>
            <div v-if="loadImportProducts()" class="page-add-button-containear">
                <Button title="Importar produtos" :text="'Importar produtos'" :iconName="'note_add'"
                    :action="import_vf" />
            </div>
            <div v-if="can('PRODUCT', 'UPDATE')" class="page-add-button-containear">
                <Button title="Adicionar novos produtos" :text="'Adicionar Produtos'" :iconName="'note_add'" :action="() => {
                this.show_add_product_modal = true;
                this.showAddProductModal = true;
            }
                " />
            </div>

            <a id="download-pdf" style="display: none"></a>
            <!-- <div class="page-add-buttons-button-active" title="Baixar PDF" v-on:click="generate_pdf()">
                <span class="material-icons page-add-buttons-pdf-icon-active">picture_as_pdf</span>
                <div class="page-add-buttons-disable-text-active">PDF</div>
            </div> -->
            <Button :action="openExcelSelect" class="page-add-button-containear" :iconName="'folder'" :text="'Excel'" />
            <Button :action="openOptionsSelect" :iconName="'print'" :text="'Imprimir'" />
            <!-- <CheckBox class="page-filter-inputs-checkbox"
                :value="purchased_only"
                :action="togglePurchaseOnly"
            /> -->

        </div>

        <div class="page-filter-containear">
            <div class="page-filter-inputs">
                <Input class="desktop" :title="'Buscar por nome'" :action="setValue" :width="'22%'" :type="'text'" />
                <Input class="mobile" :title="'Buscar por nome'" :action="setValue" :width="'100%'" :type="'text'" />
                <Input class="page-filter-inputs-category desktop" :action="setValueList"
                    :list="formatArrayCategoria(arrayCategoria)" :value="categoria" :title="'Categoria'" :width="'18%'"
                    :type="'select'" />
                <Input class="page-filter-inputs-category desktop" :action="setGroup" :list="groups" :title="'Grupo'"
                    :width="'18%'" :type="'select'" />
                <Input class="page-filter-inputs-category mobile" :action="setValueList"
                    :list="formatArrayCategoria(arrayCategoria)" :value="categoria" :title="'Categoria'" :width="'100%'"
                    :type="'select'" />
                <div class="page-filter-inputs-checkbox-left">
                    <CheckBox :value="purchased_only" :action="togglePurchaseOnly" />
                </div>
                <div class="page-filter-inputs-checkbox-left">
                    <CheckBox text="Itens inativos" :value="excludedOnly"
                        :action="() => excludedOnly = !excludedOnly" />
                </div>
            </div>
        </div>

        <div class="page-selected-filters-containear">
            <div v-if="buscaProduto" class="page-selected-filters">
                <div class="page-selected-filters-text">{{ buscaProduto }}</div>
                <span v-on:click="setValue('')" class="material-icons page-selected-filters-icon">close</span>
            </div>
            <div v-if="categoria" class="page-selected-filters">
                <div class="page-selected-filters-text">
                    {{ categoria.cat_descricao }}
                </div>
                <span v-on:click="setValueList('')" class="material-icons page-selected-filters-icon">close</span>
            </div>
        </div>

        <div v-if="!loading" class="page-table-containear">
            <div class="page-table-header">
                <div class="page-table-header-text code-width">Código
                    <div v-if="can('PRODUCT', 'UPDATE')" @click="checkAll = !checkAll;"
                        style="display: flex;color: #202020; font-size: 1.1em; ">
                        <span v-if="checkAll" class="material-icons-outlined add-product-icon">add_task</span>
                        <span v-else class="material-icons-outlined add-product-icon">radio_button_unchecked</span>
                    </div>
                </div>
                <div class="page-table-header-text desc-width">Descrição</div>
                <div class="page-table-header-text unit-width">Embalagem</div>
                <div class="page-table-header-text price-width">
                    Último Preço
                </div>
                <div class="page-table-header-text date-width">
                    Data da Últ. Compra
                </div>
                <div class="page-table-header-text-action action-table-width">
                    Ações
                </div>
            </div>
            <div v-for="(product, index) in products" :key="product.prod_id" :class="getSpecialBackground(index)">
                <div class="page-table-row-containear" :style="getExpandRow(product)">
                    <div class="page-table-row">
                        <div class="page-table-code-colum code-width">
                            <div class="page-table-expand-containear">
                                <div
                                    v-if="product.info_adicional || product.infoadicionais && product.infoadicionais.length > 0">
                                    <img title="Escoder Historico de Compras" v-if="product.expanded"
                                        v-on:click="toogleCollapse(product)" src="@/assets/expand.svg"
                                        class="icon page-table-actions-icon-expand" alt="" />
                                    <img title="Ver Historico de Compras" v-else v-on:click="toogleCollapse(product)"
                                        src="@/assets/expand.svg" class="icon page-table-actions-icon-colapse" alt="" />
                                </div>
                            </div>
                            <div>{{ product.pro_ean }}</div>
                            <template v-if="can('PRODUCT', 'UPDATE')">
                                <span @click="product.isAddCheck = !product.isAddCheck; $forceUpdate()"
                                    v-if="product.isAddCheck"
                                    class="material-icons-outlined add-product-icon">add_task</span>
                                <span @click="product.isAddCheck = !product.isAddCheck; $forceUpdate()" v-else
                                    class="material-icons-outlined add-product-icon">radio_button_unchecked</span>
                            </template>
                            <div v-else>
                                <!-- placeholder -->
                            </div>

                        </div>

                        <div class="page-table-desc-colum desc-width mobile-product-name">
                            {{ product.pro_descricao }}
                        </div>

                        <!-- desktop -->
                        <div v-if="product.pro_generico == 1" class="page-table-unit-colum unit-width desktop">
                            <div class="page-table-mobile-header">
                                Embalagem
                            </div>
                            {{ product.pro_unidade_compra }}
                        </div>

                        <!-- desktop -->
                        <div v-else class="page-table-unit-colum unit-width desktop">
                            <div class="page-table-mobile-header">
                                Embalagem
                            </div>
                            {{ product.pro_embalagem }}
                        </div>
                        <!-- desktop -->
                        <div v-if="product.info_adicional" class="page-table-price-colum price-width desktop">
                            <div class="page-table-mobile-header">
                                Último Preço
                            </div>
                            {{
                product.info_adicional[0].valor_unitario
                | currency
            }}
                        </div>
                        <!-- desktop -->
                        <div v-else class="page-table-price-colum price-width desktop">
                            <div class="page-table-mobile-header">
                                Último Preço
                            </div>
                            R$ 0,00
                        </div>
                        <!-- desktop -->
                        <div class="page-table-date-colum date-width desktop">
                            <div class="page-table-mobile-header">
                                Data da Últ. Compra
                            </div>
                            {{
                    product.data_ultima_compra
                    | moment("DD/MM/YYYY")
                }}
                        </div>

                        <!-- mobile -->
                        <div class="mobile mobile-table-row">
                            <div v-if="product.pro_generico == 1" class="page-table-unit-colum unit-width">
                                <div class="page-table-mobile-header">
                                    Embalagem
                                </div>
                                {{ product.pro_unidade_compra }}
                            </div>
                            <div v-else class="page-table-unit-colum unit-width desktop">
                                <div class="page-table-mobile-header">
                                    Embalagem
                                </div>
                                {{ product.pro_embalagem }}
                            </div>
                            <div v-if="product.info_adicional" class="page-table-price-colum price-width">
                                <div class="page-table-mobile-header">
                                    Último Preço
                                </div>
                                {{
                product.info_adicional[0].valor_unitario
                | currency
            }}
                            </div>
                            <div v-else class="page-table-price-colum price-width">
                                <div class="page-table-mobile-header">
                                    Último Preço
                                </div>
                                R$ 0,00
                            </div>
                            <div class="page-table-date-colum date-width">
                                <div class="page-table-mobile-header">
                                    Últ. Compra
                                </div>
                                {{
                    product.data_ultima_compra
                    | moment("DD/MM/YYYY")
                }}
                            </div>
                        </div>

                        <div class="page-table-actions-colum">
                            <div class="page-table-action-row">
                                <span title="Deletar produto" class="material-icons page-table-actions-icon-delete icon"
                                    @click="delete_product($event, product)"
                                    v-if="can('PRODUCT', 'DELETE')">delete</span>
                                <span title="Editar Produto" class="material-icons editproduct" @click="
                                    editProduct($event, product);
                                    showNewProductModal = true;
                                    " v-if="can('PRODUCT', 'UPDATE')">edit</span>
                                <span title="Histórico de alterações" v-on:click="() => (showProductLog = product)
                                    " class="material-icons-outlined log-icons">format_list_bulleted</span>
                                <span class="material-icons-outlined history-icons" title="Histórico do produto"
                                    v-on:click="showProductHistory = product">inventory</span>
                                <span v-if="can('PRODUCT', 'UPDATE')" title="Adicionar produto a um grupo"
                                    class="material-icons-outlined add-icons" @click="addProduct = product">add</span>
                                <img title="Ver marcas" v-if="product.pro_generico != 0" src="@/assets/Boxes.svg"
                                    class="icon page-table-actions-icon-package" @click="showMarcas($event, product)" />
                            </div>
                        </div>
                    </div>
                    <div v-if="product.expanded">
                        <div class="page-table-row-extrainfo-header">
                            <div class="suplier-width">Fornecedor</div>
                            <div class="brand-width">Marca</div>
                            <div class="buydate-width">Data Compra</div>
                            <div class="cotnum-width">Número Cotação</div>
                            <div class="req-width">N. Requisitantes</div>
                            <div class="quantity-width">Quantidade</div>
                            <div class="package-width">Embalagem</div>
                            <div class="unitvalue-width">Valor Unit</div>
                            <div class="totalvalue-width">Valor Total</div>
                        </div>
                        <div v-for="(line, idx2) in product.info_adicional || product.infoadicionais" :key="idx2"
                            class="page-table-row-extrainfo-body">
                            <div class="suplier-width mobile-product-name">
                                {{ line.fornecedor }}
                            </div>
                            <div class="brand-width mobile-product-name">
                                {{ line.marca }}
                            </div>

                            <!-- desktop -->
                            <div class="buydate-width desktop">
                                <div class="page-table-mobile-header">
                                    Data Compra
                                </div>
                                {{ line.data_compra | moment("DD/MM/YYYY") }}
                            </div>
                            <!-- desktop -->
                            <div class="cotnum-width desktop">
                                <div class="page-table-mobile-header">
                                    Número Cotação
                                </div>
                                <div class="req-number" v-on:click="() => open_req_modal(product, line)
                ">
                                    {{ line.numero_cotacao }}
                                </div>
                            </div>
                            <!-- desktop -->
                            <div class="req-width desktop">
                                <div class="page-table-mobile-header">
                                    N. Requisitantes
                                </div>
                                {{ line.numero_requisitante }}
                            </div>

                            <!-- mobile -->
                            <div class="mobile mobile-table-row">
                                <div style="
                                        line-height: 1.2;
                                        width: 30%;
                                        text-align: left;
                                    ">
                                    <div>Compra</div>
                                    {{
                line.data_compra | moment("DD/MM/YYYY")
            }}
                                </div>
                                <div style="line-height: 1.2">
                                    <div>Número Cot.</div>
                                    {{ line.numero_cotacao }}
                                </div>
                                <div style="
                                        line-height: 1.2;
                                        width: 35%;
                                        text-align: right;
                                    ">
                                    <div>N. Requisitantes</div>
                                    {{ line.numero_requisitante }}
                                </div>
                            </div>

                            <!-- desktop -->
                            <div class="quantity-width desktop">
                                {{ line.quantidade }}
                            </div>
                            <!-- desktop -->
                            <div class="package-width desktop" >
                                {{ product.pro_generico == 0 ? line.item_embalagem : product.pro_unidade_compra }}
                            </div>
                            <!-- desktop -->
                            <div class="unitvalue-width desktop">
                                <div class="page-table-mobile-header">
                                    Valor Unit
                                </div>
                                {{ line.valor_unitario | currency }}
                            </div>
                            <!-- desktop -->
                            <div class="totalvalue-width desktop">
                                <div class="page-table-mobile-header">
                                    Valor Total
                                </div>
                                {{ getTotal(line, product) | currency }}


                            </div>

                            <!-- mobile -->
                            <div class="mobile mobile-table-row">
                                <div style="
                                        line-height: 1.2;
                                        width: 30%;
                                        text-align: left;
                                    ">
                                    <div>Quantidade</div>
                                    {{ line.quantidade }}
                                </div>
                                <div style="line-height: 1.2">
                                    <div>Valor Unit.</div>
                                    {{ line.valor_unitario | currency }}
                                </div>
                                <div style="
                                        line-height: 1.2;
                                        width: 35%;
                                        text-align: right;
                                    ">
                                    <div>Valor Total</div>
                                    {{ line.valor_total | currency }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Print only -->
        <div hidden class="modal-mask">
            <div class="modal-wrapper" id="products-modal">
                <div class="modal-container">
                    <div class="modal-header">
                        <slot name="header">
                            <div class="row" style="width: 100%;">
                                <div style="display: flex; gap: 40px; align-items: center;">
                                    <img class="logo" src="/images/logotipo.png" />
                                    <div>Meus Produtos</div>
                                    <div>Usuário: {{ user.user_name }}</div>
                                    <div>Empresa: {{ user.group }}</div>
                                </div>
                                <div style="margin-top: 1vh;">Data: {{ getDate() }}</div>
                            </div>

                        </slot>
                    </div>


                    <div class="modal-body">
                        <div class="row" id="modalBodyPrint">
                            <div class="table">
                                <div  style="border-bottom: 1px solid gray;" class="print-table-row">
                                    <div class="print-table-desc">Descrição</div>
                                    <div style="width:10vw;  text-align: center;"> Estoque/Pedido</div>
                                    <div style="width:10vw;  text-align: center;"> Estoque/Pedido</div>
                                    <div style="width:10vw;  text-align: center;"> Estoque/Pedido</div>
                                    <div style="width:10vw;  text-align: center;"> Estoque/Pedido</div>
                                </div>
                                <div style="border-bottom: 1px solid gray;" v-for="item in printProducts" v-bind:key="item.prod_id">
                                    <div class="print-table-row">
                                        <div class="print-table-desc">{{ item.pro_descricao }}</div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                    </div>
                                    <div class="print-table-row">
                                        <div class="print-table-ean">EAN: {{ item.pro_ean }}</div>
                                        <div class="print-table-eamb">Embalagem: {{ item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem }}</div>
                                        <div class="print-table-cat">Categoria: {{ item.cat_descricao }}</div>
                                        <div class="print-table-est">Generico: {{ item.pro_generico ? 'sim' : 'não' }}</div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                        <div style="width: 5vw; border-left: 0.1vw solid gray; "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <tr class="seemore-button-containear" v-show="total_items > items_by_page_value">
            <td class="text-center" colspan="6">
                <Paginacao classes="orange justify-end" :items_by_page_value="items_by_page_value" :total="total_items"
                    :page="page" v-on:change="updatePage($event)"></Paginacao>
            </td>
        </tr>

        <cc-loader-full v-if="loading || loading2" />
        <cc-product-brands-modal v-if="brands && showEditBrandsModal" :closeDialog="closeEditBrandsModal"
            :brands="brands" :product="current_product" />
        <AddToGroupModal v-if="addProduct" :product="addProduct" :update="() => getListaCategorias()"
            :close="() => addProduct = undefined" />
        <ProductManagementModal v-if="showManagementProducts" :close="() => showManagementProducts = false" />
        <cc-client-categories v-if="manage_categories && showClientCategoriesModal" @close="manage_categories = false"
            :closeDialog="closeClientCategoriesModal" />
        <new-product-modal v-if="new_product && showNewProductModal" :closeDialog="closeNewProductModal"
            :product="new_product" :categories="arrayCategoria" @reload_products="getListaProdutos"
            @close="new_product = null" :editProductCategoryAndIntCode="editProductCategoryAndIntCode" />
        <add-product-modal id="modal" :add_only="true" v-if="show_add_product_modal && showAddProductModal"
            :closeDialog="closeAddProductModal" @close="show_add_product_modal = false"
            @add_product="getListaProdutos(1)" />
        <ProductsLog v-if="showProductLog" :product="showProductLog" :close="() => (showProductLog = false)" />
        <ProductHistory v-if="showProductHistory" :product="showProductHistory"
            :close="() => showProductHistory = false" />
        <cc-request-details-modal v-if="current_info &&
                current_product.pro_id &&
                show_product_request_details_modal
                " :product="current_product" :product_id="current_info.pro_id" :provider_id="current_info.for_id"
            :seller_id="current_info.id_vendedor" :price="current_info.valor_unitario"
            :request_id="current_info.numero_cotacao" @close="() => (show_product_request_details_modal = false)" />
    </div>
</template>

<script>
import { loaderMixin } from "@/mixins/sweet-loader.mixin";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import { perm_mixin } from "@/mixins/permission.mixin";
import { mapGetters } from "vuex";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import AddProductModal from "@/modals/add-product/add-product.modal";
import Paginacao from "@/components/cliente/base-components/Pagination";
import Button from "@/components/ui/buttons/Standard-Button.vue";
import Input from "@/components/ui/inputs/StandardInput.vue";
import CheckBox from "@/components/ui/inputs/StandardCheckBox.vue";
import * as CONSTANTS from "@/constants/constants";
import ProductBrandsModal from "@/modals/product-brands/product-brands.modal";
import NewProductModal from "@/modals/new-product/new-product.modal";
import ManageCategoriesModal from "@/modals/client/categories/manage-categories.modal";
import ProductService from "@/services/ProductService";
import StorageService from "@/services/local-storage.service";
import VarejoFacilService from "@/services/VarejoFacilService";
import QuotationService from "@/services/QuotationService";
import ProductsLog from "./ProductsLog.vue";
import RequestDetailsModal from "@/modals/products/request-details/request-details.modal";
import ProductHistory from "./ProductHistory.vue";
import { mapState } from "vuex";
import ProductManagementModal from "./ProductManagementModal.vue";
import AddToGroupModal from "./AddToGroupModal.vue";
import ProductGroupService from '@/services/v3/management/ProductGroupService.js';
import MyProductsExcelDownload from '@/services/MyProductsExcelDownload.js'

export default {
    name: "myProductTab",
    mixins: [typingRemoteRequestControlMixin, perm_mixin, loaderMixin],
    components: {
        Paginacao,
        Button,
        Input,
        "cc-request-details-modal": RequestDetailsModal,
        CheckBox,
        "new-product-modal": NewProductModal,
        "cc-product-brands-modal": ProductBrandsModal,
        "add-product-modal": AddProductModal,
        "cc-client-categories": ManageCategoriesModal,
        ProductsLog,
        ProductHistory,
        ProductManagementModal,
        AddToGroupModal
    },
    data() {
        return {
            editProductCategoryAndIntCode: true,
            showNewProductModal: true,
            showAddProductModal: true,
            showProductHistory: false,
            excludedOnly: false,
            show_product_request_details_modal: false,
            showClientCategoriesModal: true,
            showManagementProducts: false,
            addProduct: undefined,
            loading: true,
            loading2: false,
            checkAll: false,
            page: 1,
            totalPaginas: 1,
            buscaProduto: "",
            categoria: "",
            products: [],
            arrayCategoria: [],
            printProducts: [],
            items_by_page_value: 30,
            total_items: 1000,
            brands: null,
            current_product: {},
            current_info: null,
            buscaMarca: "",
            purchased_only: false,
            manage_categories: false,
            product_service: new ProductService(),
            storage_svc: new StorageService(),
            vf_svc: new VarejoFacilService(),
            service: new QuotationService(),
            groupService: new ProductGroupService(),
            groups: [],
            new_product: null,
            show_add_product_modal: false,
            hidden_button_pdf: true,
            showEditBrandsModal: true,
            showProductLog: false,
            options: [
                {
                    label: "Novo Produto",
                    action: () => {
                        this.show_add_product_modal = true;
                    },
                },
            ],
            breadcrumbs: [
                {
                    name: "Meus Produtos",
                    route: "products",
                },
            ],
        };
    },
    watch: {
        purchased_only: function (checked) {
            this.page = 1;
            this.getListaProdutos();
        },
        checkAll(value) {
            if (value) this.products.forEach(element => element.isAddCheck = true)
            else this.products.forEach(element => element.isAddCheck = false)
        },
        excludedOnly() {
            this.getListaProdutos(1)
        },
        categoria: function () {
            this.page = 1;
            this.getListaProdutos();
        },
        buscaProduto: function () {
            this.page = 1;
            this.getListaProdutos();
        },
    },
    methods: {
        open_req_modal(product, req) {
            this.show_product_request_details_modal = true;
            this.current_info = req;
            this.current_product = product;
            console.log(product, req);
        },
        setGroup(value) {
            if (!value) {
                this.getListaProdutos(0)
                return
            }
            this.products = value.items.map(element => { return element.produto })
            this.total_items = undefined
        },
        addAllProducts() {
            let addToGroupList = this.products.filter(value => value.isAddCheck)
            this.products.forEach(element => element.isAddCheck = false)
            this.checkAll = false
            if (addToGroupList.length == 0) {
                this.$swal.fire(
                    "Desculpe.",
                    "Selecione ao menos um produto para adicionar ao grupo.",
                    "error"
                );
                return
            }
            this.addProduct = addToGroupList
        },
        getDate() {
            let date = new Date()
            return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + '  ' + this.twoDigitsNumber(date.getHours()) + ':' + this.twoDigitsNumber(date.getMinutes()) + ':' + this.twoDigitsNumber(date.getSeconds())
        },
        twoDigitsNumber(value) {
            if (String(value).length > 1) return value
            return '0' + value
        },
        getSpecialBackground(index) {
            if (index % 2 != 0) return "page-table-line-special";
        },
        getTotal(line, product){
            if (product.pro_generico == 0) return line.valor_unitario * parseFloat(line.item_embalagem.split(' ')[1]) * line.quantidade
            return line.valor_total
        },
        closeNewProductModal: function () {
            this.showNewProductModal = false;
        },
        closeAddProductModal: function () {
            this.showAddProductModal = false;
        },
        closeClientCategoriesModal: function () {
            this.showClientCategoriesModal = false;
        },
        openOptionsSelect() {
            this.$swal.fire({
                icon: 'info',
                title: 'Impressão de relatório',
                text: 'Deseja imprimir o relatório selecionado?',
                iconHtml: '<span class="material-icons-outlined print-icon" style="font-size: 1em">print</span>',
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Imprimir"
            }).then(resp => {
                if (resp.isConfirmed) this.printClick()
                // else if (resp.isDenied) this.generate_pdf()
            })
        },
        openExcelSelect(){
            this.$swal.fire({
                icon: 'info',
                title: 'Gerar Excel',
                text: 'Deseja gerar um relatorio em Excel?',
                iconHtml: '<span class="material-icons-outlined print-icon" style="font-size: 1em">folder</span>',
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Gerar Excel"
            }).then(resp => {
                if (resp.isConfirmed) {
                    this.loading = true
                    const params = {
                        nomeproduto: this.buscaProduto,
                        comprado: this.purchased_only ? this.purchased_only : null,
                        categoria: this.categoria ? this.categoria.cat_id : null,
                        deleted: this.excludedOnly ? 1 : 0,
                        page: 1,
                        pdf: 1
                    };
                    this.service.getProdutosClientePorPagina(params).then(resp => {
                        let printProducts = resp.data.produtos.map(product => {
                            return [
                                product.pro_ean, 
                                product.pro_descricao, 
                                product.pro_generico == 1 ? product.pro_unidade_compra : product.pro_embalagem,
                                ' ',
                                ' ',
                                ' ',
                                ' ',
                                ' ',
                                product.cat_descricao
                            ]
                        })
                        this.loading = false
                        new MyProductsExcelDownload(printProducts)
                    })
                    
                }
                // else if (resp.isDenied) this.generate_pdf()
            })
        },
        formatArrayCategoria(arrayCategoria) {
            let responseList = [];
            arrayCategoria.forEach((value) => {
                responseList.push({
                    value: value,
                    text: value.cat_descricao,
                });
            });
            return responseList;
        },
        togglePurchaseOnly() {
            this.purchased_only = !this.purchased_only;
        },
        setValue(value) {
            this.buscaProduto = value;
        },
        setValueList(value) {
            this.categoria = value;
        },
        toogleCollapse(product) {
            product.expanded = !product.expanded;
            this.$forceUpdate();
        },
        getExpandRow(product) {
            if (product.expanded) return "padding-bottom: 15px;";
        },
        toggle_collapse(data) {
            this.$set(data, "expanded", !data.expanded);
            this.$forceUpdate();
        },
        toggle_collapse_arrow(e, data) {
            e.stopPropagation();
            e.preventDefault();
            this.toggle_collapse(data);
        },
        getListaProdutos(page = this.page) {
            this.loading = true;
            this.cancelSearch(() => {
                this.loading = true;
            });
            const params = {
                nomeproduto: this.buscaProduto,
                comprado: this.purchased_only ? this.purchased_only : null,
                categoria: this.categoria ? this.categoria.cat_id : null,
                deleted: this.excludedOnly ? 1 : 0,
                page: page
            };
            this.verifyShowButtonPdf();
            this.service
                .getProdutosClientePorPagina(params, this.cancelSource.token)
                .then((data) => {
                    this.products = data.data.produtos.data;
                    this.loading = false;
                    this.page = data.data.produtos.current_page;
                    this.totalPaginas = data.data.produtos.last_page;
                    this.total_items = data.data.produtos.total;
                    this.products.forEach((v) => (v.expanded = false));
                })
                .catch((error) => {
                    this.loading = false;
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        getListaCategorias() {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            let url = `${CONSTANTS.API_URL}/listarcategoriascliente`;
            axios.get(url, config).then((data) => {
                this.arrayCategoria = data.data.categorias;
            });
            this.groupService.loadProductGroups().then(resp => {
                this.groups = resp.data.map(element => { return { text: element.descricao, value: element } })
                this.groups.push({ text: 'Todos', value: undefined })
            })
        },
        updatePage(new_page) {
            this.getListaProdutos(new_page);
        },
        resetaFormulario() {
            this.buscaProduto = null;
            this.categoria = null;
            this.getListaProdutos();
        },
        show(index) {
            this.tituloModal = "Cadastrar Produto";
            this.botaoCadastrar = true;
            this.$modal.show("hello-world");
        },
        editProduct(e, product) {
            e.stopPropagation();
            e.preventDefault();
            this.new_product = product;
        },
        showMarcas(e, product) {
            this.loading2 = true;
            this.showEditBrandsModal = true;
            e.stopPropagation();
            e.preventDefault();
            this.current_product = product;
            this.product_service
                .get_product_brands(product.pro_id)
                .then((data) => {
                    this.brands = data.data.produtos;
                    this.loading2 = false;
                    this.current_product = product;
                })
                .catch((error) => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        process_settings() {
            let has_vf_integration =
                StorageService.get_string("has_vf_integration");
            if (has_vf_integration) {
                this.options.push({
                    label: "Importação Varejo Facil",
                    action: this.import_vf,
                });
            }
        },
        loadImportProducts() {
            return StorageService.get_string("has_vf_integration");
        },
        import_vf() {
            let last_sync = this.setting("LAST_VAREJO_FACIL_SYNC_DATE");
            this.confirm_action({
                message:
                    "Confirma importação da base de produtos do Varejo Facil?",
                subtitle: last_sync
                    ? `Sua ultima importação foi finalizada no dia ${moment(
                        last_sync.value
                    ).format(
                        "DD/MM/YY HH:mm"
                    )}. Apenas produtos criados apartir dessa data serão importados`
                    : "Você ainda realizou nenhuma importação de produtos do Varejo Facil ou sua importação está em andamento",
                callback: () => {
                    this.loader_msg(
                        "Iniciando agendamento de importação de produtos Varejo Facil..."
                    );
                    this.vf_svc
                        .import_products()
                        .then(() => {
                            this.dismiss_loader();
                            this.$store.dispatch("user/reload_user");
                        })
                        .catch((e) => {
                            this.dismiss_loader();
                            this.present_info(
                                "Ocorreu um erro durante a importação. É possível que a equipe responsável tenha sido notificada."
                            );
                        });
                },
            });
        },
        delete_product(e, product) {
            e.stopPropagation();
            e.preventDefault();

            this.$swal
                .fire({
                    title: "Confirma a exclusão do produto?",
                    text: "Seu produto será excluído!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim!",
                    cancelButtonText: "Não!",
                })
                .then((result) => {
                    if (result.value) {
                        const data = {
                            produto: product.pro_id,
                        };
                        this.product_service
                            .delete(data)
                            .then((data) => {
                                this.getListaProdutos();
                                this.$swal.fire(
                                    "Excluído!",
                                    "Produto excluído com sucesso.",
                                    "success"
                                );
                            })
                            .catch((error) => {
                                ErrorHandlerService.handle(error, this.$store);
                            });
                    }
                });
        },
        getPriceLastPurchase(product) {
            let result = 0;
            if (product.pro_generico && product.info_adicional) {
                result = product.info_adicional[0].valor_unitario;
            } else {
                result = product.preco_ultima_compra;
            }
            return result;
        },
        excel() {
            this.loading = true;
            this.cancelSearch(() => {
                this.loading = true;
            });
            const params = {
                nomeproduto: this.buscaProduto,
                comprado: this.purchased_only ? this.purchased_only : null,
                categoria: this.categoria ? this.categoria.cat_id : null,
                page_size: 1000,
            };
            this.verifyShowButtonPdf();
            this.service
                .getProdutosClientePorPagina(params, this.cancelSource.token)
                .then((data) => { })
                .catch((error) => {
                    this.loading = false;
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        printClick() {
            this.loading = true
            const params = {
                nomeproduto: this.buscaProduto,
                comprado: this.purchased_only ? this.purchased_only : null,
                categoria: this.categoria ? this.categoria.cat_id : null,
                deleted: this.excludedOnly ? 1 : 0,
                page: 1,
                pdf: 1
            };
            this.service.getProdutosClientePorPagina(params).then(resp => {
                this.printProducts = resp.data.produtos
                this.loading = false
                this.print()
            })
        },
        print() {

            setTimeout(() => {

                var conteudoModal = window.document.getElementById("products-modal").innerHTML;

                var conteudoImpressao =
                    "<html>" +
                    "<head>" +
                    '<link rel="stylesheet" href="/css/app.css">' +
                    '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic">' +
                    "<title>" +
                    "</title>" +
                    "<style>" +
                    ".logo {width: 10rem;}" +
                    ".not-print {display: none}" +
                    ".table{margin-top: 4vh; border: 1px solid gray}" +
                    ".print-table-row{ display: flex; font-size: 1.3vw; text-transform: lowercase; padding-left: 1vw; }" +
                    ".print-table-desc{flex: 1}" +
                    ".print-table-ean{flex: 1}" +
                    ".print-table-eamb{width: 16%}" +
                    ".print-table-cat{width: 16%}" +
                    ".print-table-quan{width: 10%}" +
                    ".print-table-est{width: 8%}" +
                    ".print-table-list{width: 12%}" +
                    ".print-table-loj{width: 11%}" +
                    ".print-table-usse{width: 11%}" +
                    "</style>" +
                    "</head>" +
                    "<body>" +
                    conteudoModal +
                    "</body>";

                let name = '_blank';
                let specs = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'];
                let replace = true;

                specs = !!specs.length ? specs.join(',') : '';

                var telaImpressao = window.open("", '_blank', ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'], true);

                telaImpressao.document.write(conteudoImpressao);

                setTimeout(() => {
                    telaImpressao.document.close();
                    telaImpressao.focus();
                    telaImpressao.print();
                    telaImpressao.close();
                }, 1500);

                this.imprimindo = false;
            }, 500);
        },
        // generate_pdf() {
        //     const params = {
        //         nomeproduto: this.buscaProduto,
        //         comprado: this.purchased_only ? this.purchased_only : null,
        //         categoria: this.categoria ? this.categoria.cat_id : null,
        //     };
        //     this.loading = true;
        //     this.service
        //         .getProdutosClientePorPaginaPdf(params, this.cancelSource.token)
        //         .then((response) => response.data)
        //         .then((data) => {
        //             var file = new Blob([data], { type: "application/pdf" });
        //             var fileURL = URL.createObjectURL(file);
        //             var el = document.getElementById("download-pdf");
        //             el.download = "Relatorio-ProdutoxCategoria.pdf";
        //             el.href = fileURL;
        //             el.click();
        //             setTimeout(function () {
        //                 window.URL.revokeObjectURL(fileURL);
        //             }, 100);
        //             this.loading = false;
        //         })
        //         .catch((error) => {
        //             this.loading = true;
        //             ErrorHandlerService.handle(error, this.$store);
        //         });
        // },
        verifyShowButtonPdf() {
            if (this.buscaProduto || this.purchased_only || this.categoria) {
                this.hidden_button_pdf = false;
            } else {
                this.hidden_button_pdf = true;
            }
        },
        closeEditBrandsModal: function () {
            this.showEditBrandsModal = false;
            this.brands = null;
        },
    },
    mounted() {
        this.process_settings();
        this.getListaProdutos();
        this.getListaCategorias();
        this.$store.dispatch("user/reload_user");
        this.$store.dispatch("set_breadcrumbs", this.breadcrumbs);
    },
    computed: {
        filtrarMarcas() {
            return this.brands.filter((marca) => {
                return marca.pro_observacao
                    .toLowerCase()
                    .includes(this.buscaMarca.toLowerCase());
            });
        },
        ...mapState(["user"]),
        ...mapGetters({ setting: "user/setting" }),
    },
};
</script>

<style lang="scss" scoped>
.print-icon {
    font-size: 8em;
}

.page-add-buttons {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 40px;
}

.page-add-buttons-button {
    display: flex;
    border: 0.5px solid #e5e5e5;
    border-radius: 8px;
    padding: 17px 14px;
    align-items: center;
}

.page-add-buttons-button-active {
    display: flex;
    border: 0.5px solid #e5e5e5;
    border-radius: 8px;
    width: 8vw;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.page-add-buttons-button-active:hover {
    background-color: var(--primary-color);
}

.page-add-buttons-button-active:hover>.page-add-buttons-pdf-icon-active {
    color: white;
}

.page-add-buttons-button-active:hover>.page-add-buttons-disable-text-active {
    color: white;
}

.page-add-buttons-pdf-icon {
    color: #bbbfc9;
}

.page-add-buttons-pdf-icon-active {
    color: var(--primary-color);
}

.page-add-buttons-disable-text {
    font-weight: 600;
    font-size: 1.19em;
    line-height: 17px;
    color: #bbbfc9;
    margin-left: 10px;
}

.add-icons {
    color: lightsalmon;
    cursor: pointer;
    font-size: 1.5em;
    margin-left: 0.5vw;
}

.page-add-buttons-disable-text-active {
    font-weight: 600;
    font-size: 1.19em;
    line-height: 17px;
    color: #202020;
    margin-left: 10px;
}

.page-add-button-containear {
    margin-left: 10px;
}

.page-filter-containear {
    margin-top: 10px;
}

.page-filter-inputs {
    display: flex;
    align-items: flex-end;
}

.page-filter-inputs-checkbox {
    margin-right: 2vw;
}

.page-filter-inputs-checkbox-left {
    margin-left: 2vw;
}

.mobile {
    display: none;
}

.page-table-mobile-header {
    display: none;
}

.page-selected-filters-containear {
    display: flex;
    margin-top: 14px;
}

.page-selected-filters {
    background: #fcf9f7;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.page-selected-filters-text {
    font-weight: 300;
    font-size: 1.19em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--primary-color);
    margin-right: 5px;
}

.page-selected-filters-icon {
    color: var(--primary-color);
    cursor: pointer;
}

.page-table-action-row {
    display: flex;
    align-items: center;
}

.page-table-header {
    background: #fffefc;
    border: 0.5px solid #e5e5e5;
    display: flex;
    padding: 1vh 0;
}

.page-table-containear {
    padding-right: 40px;
    padding-bottom: 10vh;
    margin-top: 69px;
}

.page-table-header-text {
    font-weight: 600;
    font-size: 1.19em;
    color: #605f5f;
    padding-left: 5px;
}

.page-table-header-text-action {
    font-weight: 600;
    font-size: 1.19em;
    color: #605f5f;
    padding-left: 15px;
    text-align: center;
}

.page-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1em;
    color: #605f5f;
}

.page-table-row-containear {
    padding-top: 20px;
    margin-top: 10px;
}

.page-table-code-colum {
    padding-left: 10px;
    border-right: 1px solid #dde5e9;
    display: flex;
}

.code-width {
    min-width: 14%;
    display: flex;
    justify-content: space-between;
}

.page-table-desc-colum {
    padding-left: 10px;
    border-right: 1px solid #dde5e9;
}

.desc-width {
    width: 40%;
}

.page-table-unit-colum {
    padding-left: 10px;
    border-right: 1px solid #dde5e9;
}

.log-icons {
    color: lightgreen;
    font-size: 1.5em;
    margin-left: 0.5vw;
    cursor: pointer;
}

.history-icons {
    color: rgba(0, 0, 255, 0.582);
    font-size: 1.5em;
    margin-left: 0.5vw;
    cursor: pointer;
}

.unit-width {
    width: 12%;
}

.page-table-price-colum {
    padding-left: 10px;
    border-right: 1px solid #dde5e9;
}

.price-width {
    width: 10%;
}

.page-table-date-colum {
    padding-left: 10px;
    border-right: 1px solid #dde5e9;
}

.date-width {
    width: 12%;
}

.action-table-width {
    flex: 1;
}

.add-product-icon {
    color: var(--primary-color);
    margin-left: auto;
    margin-right: 1vw;
    cursor: pointer;
}

.page-table-actions-colum {
    flex: 1;
    display: flex;
    align-items: center;
}

.icon {
    cursor: pointer;
}

.editproduct {
    cursor: pointer;
    margin-right: 5px;
}

.page-table-actions-icon-package {
    height: 25px;
    filter: brightness(0) saturate(100%) invert(53%) sepia(22%) saturate(6300%) hue-rotate(352deg) brightness(101%) contrast(101%);
}

.page-table-actions-icon-expand {
    height: 15px;
}

.page-table-actions-icon-colapse {
    height: 15px;
    transform: rotate(270deg);
}

.page-table-actions-icon-delete {
    color: #cd2a2a;
    margin-right: 5px;
}

.page-table-actions-editicons {
    display: flex;
    flex: 1;
}

.page-table-row-extrainfo-header {
    display: flex;
    font-weight: 400;
    font-size: 1.27em;
    line-height: 13px;
    color: var(--primary-color);
    text-align: center;
    margin: 15px 20px 0 20px;
}

.page-table-row-extrainfo-body {
    display: flex;
    font-weight: 300;
    font-size: 1.1em;
    line-height: 13px;
    color: #605f5f;
    text-align: center;
    border-bottom: 0.5px solid #f2f2f2;
    margin: 20px 20px 0 20px;
    padding-bottom: 5px;
}

.brand-width {
    width: 10%;
}

.suplier-width {
    width: 13%;
}

.buydate-width {
    width: 14%;
}

.cotnum-width {
    width: 10%;

    .req-number {
        cursor: pointer;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        &:hover {
            color: white;
            background-color: var(--primary-color);
        }
    }
}

.req-width {
    width: 12%;
}

.quantity-width {
    width: 13%;
}
.package-width {
    width: 7%;
    text-align: left;
}

.unitvalue-width {
    width: 10%;
}

.totalvalue-width {
    flex: 1;
}

.seemore-button-containear {
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
}

.seemore-button {
    background: var(--primary-color);
    border-radius: 8px;
    padding: 12px 30px;
    font-weight: 500;
    font-size: 1.36em;
    line-height: 19px;
    color: #ffffff;
    cursor: pointer;
}

.page-filter-inputs-category {
    margin-left: 30px;
}

.page-table-expand-containear {
    min-width: 3vw;
}

.page-table-line-special {
    background: #f7f7f7;
}

.winners-page-options-select {
    position: absolute;
    z-index: 2;
    margin-top: 1vh;
    right: 0;
    padding: 0.5vw;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    background-color: white;
}

.winners-page-options-select-option {
    font-size: 1.25em;
    padding: 0.5vh;
    min-width: 15vw;
    text-align: end;
    cursor: pointer;
}

.winners-page-options-select-option:hover {
    background-color: var(--primary-color);
    border-radius: 5px;
    color: white;
}

@media only screen and (min-width: 1400px) {
    .editproduct {
        font-size: 32px;
    }

    .page-table-actions-icon-delete {
        font-size: 32px;
    }
}

@media only screen and (max-width: 850px) {
    .page {
        font-size: 2vw;
    }

    .page-table-header {
        display: none;
    }

    .page-table-row {
        flex-direction: column;
        font-size: 1.4em;
    }

    .unit-width {
        width: fit-content;
        border-right: none;
    }

    .desc-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-right: none;
    }

    .code-width {
        width: 100%;
        border-right: none;
    }

    .price-width {
        width: fit-content;
        border-right: none;
    }

    .date-width {
        width: fit-content;
        border-right: none;
    }

    .page-table-actions-colum {
        flex: unset;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
    }

    .page-table-mobile-header {
        display: flex;
        font-weight: 700;
        font-size: 1.1em;
    }

    .page-table-row-extrainfo-header {
        display: none;
    }

    .page-table-row-extrainfo-body {
        flex-direction: column;
        font-size: 1.45em;
        gap: 1.5vh;
        border-bottom: 1px solid lightgray;
        padding-bottom: 2vh;
    }

    .brand-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .suplier-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .buydate-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .cotnum-width {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .req-number {
            cursor: pointer;
        }
    }

    .req-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .quantity-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .unitvalue-width {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .totalvalue-width {
        flex: unset;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 750px) {
    .page-filter-inputs {
        margin-right: 20px;
        flex-direction: column;
    }

    .mobile-product-name {
        font-weight: bold;
    }

    .page-filter-inputs-category {
        margin-left: 0;
        margin-top: 10px;
    }

    .page-filter-inputs-checkbox {
        width: 100%;
    }

    .checkbox-containear {
        justify-content: flex-start;
    }

    .mobile {
        display: block;
    }

    .mobile-table-row {
        justify-content: space-between;
        display: flex;
    }

    .page-table-action-row {
        justify-content: center;
        gap: 5vw;
        width: 100%;
    }

    .desktop {
        display: none;
    }

    .page-add-buttons {
        margin-right: 20px;
        margin-top: 15px;
        justify-content: space-between;
    }

    .page-table-containear {
        padding-right: 30px;
        margin-top: 0;
    }

    .page-add-button-containear {
        width: 35%;
    }

    .page-add-buttons-button-active {
        width: 23%;
    }
}
</style>
