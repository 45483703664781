<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="request-providers__main-container">
        <div class="request-providers__header-container">
          <span class="request-providers__header-title">
            Fornecedores da cotação #{{ requestSelected.numeroCotacao }}
          </span>
          <span @click="closeDialog" class="material-icons request-providers__closebtn-icon">
            cancel
          </span>
        </div>
        <div class="request-provider-body-container">
          <div class="request-providers__providers-info__container">
            <div class="request-providers__total-providers__wrapper mobile-info-size">
              <span  class="request-providers__total-providers__title">Total de Fornecedores -
                ({{ providers.length }})</span>
            </div>
            <div class="request-providers__engaged-providers__wrapper mobile-info-size">
              <span class="request-providers__engaged-providers__title">Fornecedores Engajados -
                ({{ get_providers_participant() }})</span>
            </div>
            <div class="request-providers__total-entries mobile-info-size">Quantidade de registros: {{ filterProvider(providers).length }}
            </div>
            <StandardInput style="margin-left: 2vw;" class="mobile-input-size" :placeholder="'Selecione o status'" :action="setStatusValue"
               :list="arraySelectStatus" :type="'select'" />

            <StandardButton class="requets-provider-print-button mobile-input-size" :action="printScrean" iconName="print"
              text="Imprimir" />
          </div>

          <cc-loader style="margin-top: 20vh; max-height: none !important;" v-if="loading" />

          <div v-else id="printMe" class="request-provider-table-size">
            <div style="display: flex; align-items: center">
              <img src="@/assets/Logotipo.png" class="only-print request-print-logo">
              <span class="only-print request-print-title">
                Fornecedores da cotação #{{ requestSelected.numeroCotacao }}
              </span>
            </div>
            <div class="request-providers__table-container">
              <!-- desktop -->
              <table class="request-providers__table-wrapper desktop">
                <thead class="request-providers__table-thead">
                  <tr class="request-providers__table-thead-tr">
                    <th class="request-providers__table-th request-providers__table-th-provider request-provider-width">
                      Fornecedor</th>
                    <th class="request-providers__table-th request-seller-width">
                      Vendedor / Email
                    </th>
                    <th class="request-providers__table-th request-phone-width">Telefone</th>
                    <th class="request-providers__table-th request-providers__table-th-cond request-payment-width">Cond.
                      Pgto.</th>
                    <th class="request-providers__table-th request-providers__table-th-date request-date-width">Data
                      Entrega</th>
                    <th class="request-providers__table-th request-min-width">Fat. Min.</th>
                    <th class="request-providers__table-th request-participation-width">Participação</th>
                    <th class="request-providers__table-th request-obs-width">Observação</th>
                    <th class="request-providers__table-th request-status-width">Status</th>
                  </tr>
                </thead>
                <tbody class="request-providers__table-tbody">
                  <tr v-for="(item, index) in filterProvider(providers)" :key="item.produto_id"
                    class="request-providers__table-tbody-tr" :class="getSpecialBackground(index)">
                    <td class="request-providers__table-td request-providers__table-th-provider">
                        {{ item.fornecedor_nome || '-' }}
                        </td>
                    <td class="request-providers__table-td">
                        <span v-if="showCopy" @click="() => copy(`https://novo.fornecedor.clubdacotacao.com.br/cotacoes/${requestSelected.numeroCotacao}/vendedor/${item.id_vendedor}?token=${item.auth_token}`)"  class="material-icons request-copy-icon">
                            file_copyv
                        </span>
                        {{ item.vendedor_nome || '-' }} <br /> {{ item.vendedor_email || '-' }}
                    </td>
                    <td class="request-providers__table-td"><cc-whatsapp :phone="item.vendedor_telefone" /></td>
                    <td class="request-providers__table-td">{{ item.entrega ? item.entrega.forma : '-' }}</td>
                    <td class="request-providers__table-td request-providers__table-th-date">{{ item.entrega &&
                      item.entrega.data_entrega ? formatDate(item.entrega.data_entrega) : '-' }}</td>
                    <td class="request-providers__table-td">{{ item.entrega && item.entrega.faturamento_minimo ?
                      item.entrega.faturamento_minimo.toLocaleString('pt-BR', {
                        currency: 'BRL',
                        style: 'currency',
                        minimumFractionDigits: 2
                      }) : '-' }}</td>
                    <td class="request-providers__table-td">
                      <div v-if="item.participation" :class="getPercent(item.participation)">{{ (item.participation *
                        100).toFixed(2) + ' %' }}</div>
                    </td>
                    <td class="request-providers__table-td request-obs-width" style="width: 5%;"><span
                        v-if="item.entrega && item.entrega.observacao" style="color: var(--primary-color);"
                        :title="item.entrega.observacao" class="material-icons-outlined">info</span></td>
                    <td class="request-providers__table-td">
                      <div class="table-not-typed" v-if="item.status == 'NOT_TYPED'">Não digitada</div>
                      <div class="table-typed" v-if="item.status == 'TYPED'">Digitada</div>
                      <div class="table-freezed" v-if="item.status == 'FROZEN'">CONGELADA</div>
                      <div class="table-created" v-if="item.status == 'ORDERED'">Pedido Gerado</div>
                      <div class="table-nooffer" v-if="item.status == 'NOT_OFFERED'">SEM OFERTA</div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- mobile -->
              <div class="mobile" v-for="(item, index) in filterProvider(providers)" :key="item.produto_id"
                  :class="getSpecialBackground(index)">
                <p class="mobile-name-table">{{ item.fornecedor_nome|| '-' }}</p>
                <div class="mobile-table-row">
                    <div style="width: 30vw;" class="table-not-typed" v-if="item.status == 'NOT_TYPED'">Não digitada</div>
                    <div style="width: 30vw;" class="table-typed" v-else-if="item.status == 'TYPED'">Digitada</div>
                    <div style="width: 30vw;" class="table-freezed" v-else-if="item.status == 'FROZEN'">CONGELADA</div>
                    <div style="width: 30vw;" class="table-created" v-else-if="item.status == 'ORDERED'">Pedido Gerado</div>
                    <div style="width: 30vw;" class="table-nooffer" v-else-if="item.status == 'NOT_OFFERED'">SEM OFERTA</div>
                    <div v-else>--</div>
                    <div style="display: flex; gap: 1vw;">
                        <p class="mobile-table-title">Participação:</p>
                        <div style="width: 20vw;" v-if="item.participation" :class="getPercent(item.participation)">{{ (item.participation *
                            100).toFixed(2) + ' %' }}</div>
                    </div>
                </div>
                <div class="mobile-table-row">
                  <div style="width: 30%;">
                    <p class="mobile-table-title">Vendedor</p>
                    {{ item.vendedor_nome || '-' }}
                  </div>
                  <div class="mobile-table-center">
                    <p class="mobile-table-title">Cond. Pgto.</p>
                    {{ item.entrega ? item.entrega.forma : '-' }}
                  </div>
                  <div class="mobile-table-center">
                    <p class="mobile-table-title">Data Entrega</p>
                    {{ item.entrega && item.entrega.data_entrega ? formatDate(item.entrega.data_entrega) : '-' }}
                  </div>
                  <div class="mobile-table-end">
                    <p class="mobile-table-title">Fat. Min.</p>
                    {{ item.entrega && item.entrega.faturamento_minimo ?
                      item.entrega.faturamento_minimo.toLocaleString('pt-BR', {
                        currency: 'BRL',
                        style: 'currency',
                        minimumFractionDigits: 2
                      }) : '-' }}
                  </div>
                  <!-- <div class="mobile-table-center">
                    <p class="mobile-table-title">Email</p>
                    {{ item.vendedor_email || '-' }}
                  </div> -->
                  <!-- <div class="mobile-table-end">
                    <p class="mobile-table-title">Telefone</p>
                    <cc-whatsapp :phone="item.vendedor_telefone" />
                  </div> -->
                </div>
                <!-- <div class="mobile-table-row">


                </div>
                <div class="mobile-table-row">
                  <div class="mobile-table-center">
                    <p class="mobile-table-title">Observação</p>
                    <span v-if="item.entrega && item.entrega.observacao" style="color: var(--primary-color);"
                        :title="item.entrega.observacao" class="material-icons-outlined">info</span>
                  </div>

                </div> -->
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>
  </div>
  <!-- <cc-modal :modal="modal" @close="close">
			<div slot="body" class="container-fluid">
				<cc-loader v-show="loading" />
				<div class="row" id="modalBodyPrint" v-show="!loading">
					<div class="col-3 div-count">
						<span class="span-count">Total de Fornecedores - ({{providers.length}})</span>
					</div>
					<div class="col-3">
						<span class="span-participant">Fornecedores Engajados - ({{get_providers_participant()}})</span>
					</div>
					<table class="table shadow">
						<thead>
						<tr>
							<th class="pl-3">FORNECEDOR</th>
							<th>VENDEDOR</th>
							<th>EMAIL</th>
							<th>TELEFONE</th>
							<th>COND. PGTO.	</th>
							<th>DATA ENTREGA</th>
							<th>FAT. MIN.</th>
							<th>OBSERVAÇÃO</th>
							<th class="text-center">STATUS</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="item in providers" v-bind:key="item.produto_id">
							<td class="pl-3">{{ item.fornecedor_nome || '-' }}</td>
							<td>{{ item.vendedor_nome || '-'}}</td>
							<td>{{ item.vendedor_email || '-' }}</td>
							<td><cc-whatsapp :phone="item.vendedor_telefone" /></td>
							<td>{{ item.entrega ? item.entrega.forma : '-' }}</td>
							<td>{{ item.entrega ? item.entrega.data_entrega : '-' }}</td>
							<td>{{ item.entrega ? item.entrega.faturamento_minimo : '-' }}</td>
							<td>{{ item.entrega ? item.entrega.observacao : '-' }}</td>
							<td class="text-center">
                                <cc-status :status="item.status"></cc-status>
                            </td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
	</cc-modal> -->
</template>
<script>

import QuotationService from "@/services/QuotationService";
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import { mapState } from 'vuex';
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';

export default {
  props: ["requestSelected", "closeDialog"],
  components: { StandardInput, StandardButton },
  mixins: [ loaderMixin ],
  computed: mapState(['statuses']),
  data() {
    return {
      loading: false,
      service: new QuotationService(),
      providers: [],
      arraySelectStatus: [],
      selectedStatus: undefined,
      showCopy: true
    }
  },
  methods: {
    printScrean() {



      var conteudoModal = window.document.getElementById("printMe").innerHTML;
      var conteudoImpressao =
        "<html>" +
        "<head>" +
        '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css">' +
        '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic">' +
        "<title>" +
        "</title>" +
        "<style> \
              @media print {    \
                  .not-print, .not-print * { \
                      display: none !important; \
                  } \
                  .show-print, .show-print * { \
                      display: table-cell !important; \
                  } \
                  .request-print-title{font-size: 1.5em; margin-left: 5vw} \
                  .request-print-logo{width: 125px; heigth: 60px;} \
                  .request-providers__table-wrapper{border: 2px solid lightgray; margin-top: 5vh; font-size:1.5vw} \
                  .table { border-collapse: collapse; } \
                  tr {  border-bottom: 2px solid lightgray;} \
                  .request-provider-width{width: 15%} \
                  .request-providers__table-tbody-tr {break-inside: avoid;} \
                  .request-seller-width{ flex: 1} \
                  .request-phone-width{width: 10%} \
                  .request-payment-width{width: 10%} \
                  .request-date-width{width: 10%} \
                  .request-min-width{width: 10%} \
                  .request-participation-width{width: 10%} \
                  .request-obs-width{display: none} \
                  .request-providers__table-td {border-right: 2px solid lightgray; padding-left: 7px} \
                  .request-providers__table-th{border-right: 2px solid lightgray; padding-left: 7px} \
                  .request-status-width{width: 10%} \
              } \
          </style>" +
        "</head>" +
        "<body>" +
        conteudoModal +
        "</body>";
      var telaImpressao = window.open("", '_blank', ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'], true);
      telaImpressao.document.write(conteudoImpressao);
      setTimeout(() => {
        telaImpressao.document.close();
        telaImpressao.focus();
        telaImpressao.print();
        telaImpressao.close();
      }, 200);

    },
    copy(value) {

        this.snack("Link Copiado")
        navigator.clipboard.writeText(value);
    },
    getPercent(value) {
      if (value <= 0.3) return 'table-not-typed'
      else if (value >= 0.7) return 'table-typed'
      else return 'percent-orange'
    },
    filterProvider(providers) {
      if (this.selectedStatus) {
        const resp = []
        providers.forEach(item => {
          if (item.status == 'NOT_TYPED' && this.selectedStatus == 7) resp.push(item)
          else if (item.status == 'TYPED' && this.selectedStatus == 12) resp.push(item)
          else if (item.status == 'FROZEN' && this.selectedStatus == 10) resp.push(item)
          else if (item.status == 'ORDERED' && this.selectedStatus == 5) resp.push(item)
          else if (item.status == 'NOT_OFFERED' && this.selectedStatus == 1) resp.push(item)
        })
        return resp
      } else return providers
    },
    setStatusValue(value) { this.selectedStatus = value },
    formatDate(date) { return date.split('-')[2] + '/' + date.split('-')[1] + '/' + date.split('-')[0] },
    close() {
      this.$emit('close');
    },
    load_providers() {
      this.loading = true;
      return this.service.get_request_providers(this.requestSelected.numeroCotacao).then((request) => {
        this.loading = false;
        return request.data
      })
    },
    get_providers_participant() {
      let status = ['NÃO DIGITADA', 'NÃO DIGITADO', 'AGUARDANDO CONFIRMACAO', 'NOT_TYPED'];
      return this.providers.filter(p => !status.includes(p.status)).length;
    },
    getSpecialBackground(index) {
      if (index % 2 != 0) return 'page-table-line-special'
    },
  },
  created() {
    console.log(this.requestSelected);
    let date = new Date(this.requestSelected.dataExpiracao)
    let dateNow = new Date();
    console.log(this.requestSelected.status_nome);
    let isExpired = dateNow > date

    if (isExpired) this.showCopy = false
    else if (this.requestSelected.status_nome == 'PEDIDO GERADO' || this.requestSelected.status_nome == "CONGELADA") this.showCopy = false
    this.load_providers().then(data => this.providers = data.concorrentes)
    this.statuses.forEach((el) => {
      this.arraySelectStatus.push({
        text: el.name,
        value: el.value
      })
    })
    this.arraySelectStatus.push({
      text: "Sem oferta",
      value: 1
    })
  }
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-content-container {
  background-color: white;
  min-width: 50vw;
  border-radius: 12px;
}

.request-providers__main-container {
  width: 115vw;
}

.request-providers__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}

.request-providers__header-title {
  color: #FFFFFF;
  letter-spacing: -2%;
  font-weight: 500;
  font-size: 32px;
}

.request-providers__closebtn-icon {
  color: #FFFFFF;
  font-size: 30px;
  cursor: pointer;
}

.request-providers__providers-info__container {
  display: flex;
  align-items: center;
  margin: 2vh 2vw;
}

.request-providers__total-providers__wrapper {
  background-color: var(--primary-color-opacity);
  margin-right: 3vw;
  padding: 1.4vh 0.5vw;
  border-radius: 8px;
}

.requets-provider-print-button {
  height: 6vh;
  margin-left: 3vw;
}

.request-providers__total-providers__title {
  color: var(--primary-color);
  font-weight: 300;
  font-size: 1.5em;
}

.request-providers__engaged-providers__wrapper {
  background-color: #2acd721a;
  padding: 1.4vh 0.5vw;
  border-radius: 8px;
}

.request-providers__total-entries {
  background-color: rgb(204, 227, 235);
  color: rgb(79, 79, 247);
  font-size: 1.5em;
  font-weight: 300;
  padding: 1.4vh 0.5vw;
  border-radius: 8px;
  margin-left: 2vw;
}

.request-providers__engaged-providers__title {
  color: #30aa4c;
  font-weight: 300;
  font-size: 1.5em;
}
.request-provider-table-size{
  height: 85%;
  display: flex;
  flex-direction: column;
}

.request-providers__table-container {
  margin: 20px;
  overflow-y: auto;
  height: 90%;
}

.table-not-typed {
  background-color: lightcoral;
  color: white;
  border-radius: 10px;
  padding: 0 1vw;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.percent-orange {
  background-color: lightsalmon;
  color: white;
  border-radius: 10px;
  padding: 0 1vw;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.table-typed {
  background-color: lightgreen;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  border-radius: 10px;
  padding: 0 1vw;
  text-align: center;
}

.table-freezed {
  background-color: gray;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  border-radius: 10px;
  padding: 0 1vw;
  text-align: center;
}

.table-created {
  background-color: lightblue;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  border-radius: 10px;
  padding: 0 1vw;
  text-align: center;
}

.table-nooffer {
  outline: 1px solid gray;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 0 1vw;
  text-align: center;
}

.request-providers__table-wrapper {}

.request-providers__table-thead {}

.request-providers__table-thead-tr {
  background-color: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  color: #605F5F;
  font-size: 14px;
  font-weight: 400;
}

.request-providers__table-th {
  font-size: 14px;
  font-weight: 600;
}

.request-provider-body-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.request-providers__table-tbody {}

.request-providers__table-tbody-tr {}

.request-providers__table-td {
  color: #605F5F;
  font-size: 14px;
  font-weight: 300;
}
.request-copy-icon{
  cursor: pointer;
  font-size: 1.5em;
}

.request-providers__table-th-provider {
  width: 22%;
}

.request-providers__table-th-date {
  width: 7%;
}

.request-providers__table-th-cond {
  width: 7.5%;
}

.page-table-line-special {
  background: #F7F7F7;
}
.mobile{display: none;}

@media only screen and (max-width: 850px) {
  .mobile{
    display: block;
  }
  .request-providers__header-title {
    font-size: 24px;
  }

  .request-providers__total-providers__title {
    font-size: 16px;
  }

  .request-providers__engaged-providers__title {
    font-size: 16px;
  }
  .request-providers__total-entries{
    font-size: 16px;
  }

  .request-providers__table-container {
    height: 70vh;
  }
  .request-providers__providers-info__container{
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  .mobile-info-size{
    width: 31%;
    text-align:center;
    margin: 0;
    height: 12vh;
  }
  .mobile-input-size{
    width: 45%;
  }
  .request-provider-table-size{
    height: 75%;
  }
  .mobile-table-row{
    display: flex;
    justify-content: space-between;
    padding-bottom: 2vh;
  }
  .mobile-name-table{
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0;
    padding-top: 2vh
  }
  .mobile-table-title{
    font-size: 1.1em;
    font-weight: bold;
    margin: 0;
  }
  .mobile-table-end{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 20%;
  }
  .mobile-table-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
  }
  .desktop{display: none}
}

@import "./request-providers.modal";</style>
