<template>
    <div class="management-modal-mask">
        <div class="management-modal-content-container">
            <div class="management-modal-header-container">
                <h1 class="management-modal-header-title">Grupo de Produtos</h1>
                <span @click="close" class="material-icons management-modal-closebtn-icon">cancel</span>
            </div>
            <div class="management-modal-title-row">
                <h2 class="management-modal-title">{{ productsListId ? productsListId.descricao : 'Meus grupos' }}</h2>
                <StandardButton text="Voltar" :iconName="'arrow_back'" :action="() => this.backButton()" />
            </div>
            <div class="management-modal-content">
                <ListGroupsModal v-if="!productsListId" :addNewGroup="addNewGroup" :groups="groups" :editGroup="value=>editGroup=value" :productsListId="value=>productsListId=value"/>
                <ListProductsModal v-else :listId="productsListId.id" />
            </div>
            
        </div>
        <EditGroupModal v-if="editGroup" :group="editGroup" :updateGroup="updateGroup" :close="()=>editGroup=undefined"/>
        <cc-loader-full v-if="isLoading" />
    </div>

</template>

<script>
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import ProductGroupService from '@/services/v3/management/ProductGroupService.js'
import EditGroupModal from "./EditGroupModal.vue";
import ListProductsModal from "./ListProductsModal.vue";
import ListGroupsModal from "./ListGroupsModal.vue";

export default {
    name: 'ProductManagementModal',
    props: ['close'],
    components: {
        StandardButton,
        EditGroupModal,
        ListProductsModal,
        ListGroupsModal
    },
    data() {
        return {
            service: new ProductGroupService(),
            groups: [],
            isLoading: true,
            newGroupValue: '',
            editGroup: undefined,
            productsListId: undefined
        }
    },
    methods: {
        updateGroup(newName){
            this.isLoading = true
            this.service.updateGroup(newName, this.editGroup.id).then(()=>{
                this.loadPage()
            })
            this.editGroup = undefined
        },
        backButton(){
            if(this.productsListId) this.productsListId = undefined
            else this.close()
        },
        loadPage() {
            this.isLoading = true
            this.service.loadProductGroups().then(resp => {
                this.groups = resp.data
                this.isLoading = false
            })
        },
        addNewGroup(newGroupValue){
            this.isLoading = true
            this.service.createProductGroups(newGroupValue).then(resp => {
                newGroupValue = ''
                this.loadPage()
            })
        }
    },
    created() {
        this.loadPage()
    }
}
</script>

<style scoped lang="scss">
.management-modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.management-modal-content-container {
    background-color: white;
    width: 85vw;
    border-radius: 12px;
}

.management-modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.management-modal-header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2.5em;
}

.management-modal-closebtn-icon {
    color: #ffffff;
    font-size: 3em;
    cursor: pointer;
}
.management-modal-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vh 2vw;
}

.management-modal-title {
    color: #707070;
    font-weight: 400;
    font-size: 2.2em;
}
.management-modal-content{
    height: 80vh;
    padding: 3vh 2vw;
}
</style>