import http from "../http";
import Service from "../../Service";

export default class DashboardService extends Service {
    formatDate (date) {
        if (!date) return null
        return date.split('T')[0] + ' 00:00:00'
    }
    getRankingSupliers(storeId, fromDate, toDate){
        return http.get('clients/dashboard/rankingFornecedor', {
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate)}
        })
    }
    getRankingProducts(storeId, fromDate, toDate){
        return http.get('clients/dashboard/rankingProdutos', {
            timeout: 1200000,
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate)}
        })
    }
    getCotBuy(storeId, fromDate, toDate){
        return http.get('clients/dashboard/compraPorCot', {
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate)}
        })
    }
    getCategory(storeId, fromDate, toDate){
        return http.get('clients/dashboard/categoria', {
            timeout: 1200000,
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate)}
        })
    }
    getRequest(storeId, fromDate, toDate){
        return http.get('clients/dashboard/requisitante', {
            timeout: 1200000,
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate)}
        })
    }
    getCounts(storeId, fromDate, toDate){
        return http.get('clients/dashboard/counts', {
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate)}
        })
    }
    getCountsNew(storeId, fromDate, toDate){
        return http.get('clients/dashboard/counts/new', {
            headers: this.getHeaders(),
            params: {'loj_id': storeId, from: this.formatDate(fromDate), to: this.formatDate(toDate)}
        })
    }
}