<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="manage-sellers__main-container">
        <div class="manage-sellers__header-container">
          <span class="manage-sellers__header-title">
            Gerenciar fornecedores #{{ request.numeroCotacao }}
          </span>
          <span @click="closeDialog" class="material-icons manage-sellers__closebtn-icon">
            cancel
          </span>
        </div>
        <div class="manage-sellers__providers-container-top">
          <div v-if="!newSuplier" class="manage-sellers__actions-wrapper" v-on:click="newSuplier = !newSuplier">
            <span class="material-icons manage-sellers__actions-icon">add_circle_outline</span>
            <span class="manage-sellers__actions-title">
              Convidar fornecedor
            </span>
          </div>
          <div class="manage-sellers__providers-container-top invitation-modal__form-container" v-else>
            <div class="invitation-modal__input-wrapper">
              <span class="invitation-modal__input-title">Telefone: <span style="color: red">*</span></span>
              <input class="invitation-modal__input" v-mask="'(##) ####-#####'" type="text"
                v-model="invitation.receiver_phone" @blur="() => search_by_phone()">
            </div>
            <div class="invitation-modal__input-wrapper">
              <span class="invitation-modal__input-title">Nome: <span style="color: red">*</span></span>
              <input class="invitation-modal__input" type="text" v-model="invitation.receiver_name"
                :readonly="invitation.lock">
            </div>
            <div class="invitation-modal__input-wrapper">
              <span class="invitation-modal__input-title">Empresa:</span>
              <input class="invitation-modal__input" type="text" v-model="invitation.receiver_company_name"
                :readonly="invitation.lock">
            </div>
            <div class="manage-sellers__actions-wrapper" v-if="invitation.lock" v-on:click="save()">
              <span class="material-icons manage-sellers__actions-icon">email</span>
              <span class="manage-sellers__actions-title">
                Enviar
              </span>
            </div>
          </div>
        </div>
        <div class="manage-sellers__providers-container">
          <div class="manage-sellers__left-container">
            <div class="manage-sellers__left-wrapper">
              <div class="manage-sellers__title-wrapper">
                <span class="manage-sellers__title">Fornecedores da compra/cotação</span>
              </div>
              <div class="manage-sellers__input-wrapper">
                <input :placeholder="'Procurar vendedor ou fornecedor'" class="manage-sellers__input"
                  v-model="request_sellers_filters.name"
                  @keyup="filter_sellers(request_sellers, request_sellers_filters.name)">
                <span @click="filter_sellers(request_sellers, request_sellers_filters.name)"
                  class="material-icons-outlined manage-sellers__input-icon">search</span>
              </div>
              <div class="manage-sellers__actions-wrapper">
                <span class="material-icons-outlined manage-sellers__actions-icon">delete</span>
                <span @click="remove_all" :classes="'fixed danger-outline'" class="manage-sellers__actions-title">
                  Remover todos
                </span>
              </div>
              <div class="manage-sellers__table-container">
                <table class="manage-sellers__table-wrapper">
                  <thead class="manage-sellers__table-thead">
                    <tr class="manage-sellers__table-thead-tr">
                      <th class="manage-sellers__table-th">Forcenedor</th>
                      <th class="manage-sellers__table-th">Vendedor</th>
                      <th class="manage-sellers__table-th">Ação</th>
                    </tr>
                  </thead>
                  <tbody class="manage-sellers__table-tbody">
                    <tr v-show="seller.visible" v-for="seller in request_sellers" :key="seller.usu_id"
                      class="manage-sellers__table-tbody-tr">
                      <td class="manage-sellers__table-td">{{ seller.for_nome }}</td>
                      <td class="manage-sellers__table-td">{{ seller.usu_nome }}</td>
                      <td class="manage-sellers__table-td">
                        <span v-show="!seller.loading" @click.stop="remove(seller)"
                          class="material-icons-outlined delete-icon">delete</span>
                        <cc-loader style="width: 4vw;" v-show="seller.loading" :show_txt="false" class="loader" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="manage-sellers__right-container">
            <div class="manage-sellers__right-wrapper">
              <div class="manage-sellers__title-wrapper">
                <span class="manage-sellers__title">Fornecedores disponíveis</span>
              </div>
              <div class="manage-sellers__right-actions__wrapper">
                <div class="manage-sellers__right-input__wrapper">
                  <input @keyup="filter_sellers(sellers, filters.name)" v-model="filters.name"
                    :placeholder="'Procurar vendedor ou fornecedor'" class="manage-sellers__right-input">
                  <span class="material-icons-outlined manage-sellers__input-icon">search</span>
                </div>
                <div class="manage-sellers__right-filter__wrapper">
                  <cc-select-v2 :placeholder="'Filtrar por grupo'" class="manage-sellers__right-filter" :tracker="'id'"
                    :text="'nome'" :options="groups" v-model="filters.group">
                  </cc-select-v2>
                </div>
              </div>
              <div class="manage-sellers__actions-wrapper">
                <span class="material-icons manage-sellers__actions-icon">add_circle_outline</span>
                <span @click="add_all" class="manage-sellers__actions-title" :classes="'fixed success'">
                  Adicionar todos
                </span>
              </div>
              <div class="manage-sellers__table-container">
                <table class="manage-sellers__table-wrapper">
                  <thead class="manage-sellers__table-thead">
                    <tr class="manage-sellers__table-thead-tr">
                      <th class="manage-sellers__table-th">Forcenedor</th>
                      <th class="manage-sellers__table-th">Vendedor</th>
                      <th class="manage-sellers__table-th">Ação</th>
                    </tr>
                  </thead>
                  <tbody class="manage-sellers__table-tbody">
                    <tr v-for="seller in sellers" :key="seller.usu_id" v-show="seller.visible"
                      class="manage-sellers__table-tbody-tr">
                      <td class="manage-sellers__table-td">{{ seller.for_nome }}</td>
                      <td class="manage-sellers__table-td">{{ seller.usu_nome }}</td>
                      <td class="manage-sellers__table-td">
                        <span class="material-icons-outlined add-icon" v-show="!seller.loading" @click.stop="add(seller)">
                          add_circle_outline
                        </span>
                        <cc-loader style="width: 4vw;" v-show="seller.loading" :show_txt="false" class="loader" />
                      </td>
                    </tr>
                    
                  </tbody>
                  
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <cc-modal :modal="modal" @close="close">
		<div slot="body" class="container-fluid pt-0 sellers">
			<div class="row contents shadow p-4">
				<div class="col-6">
					<label for="" class="label-control">Fornecedores da compra/cotação</label>
					<cc-loader v-show="is_loading_request_sellers"/>
					<div class="col-12 mb-3">
						<div class="row">
							<div class="col p-0">
								<cc-search :placeholder="'Pesquisar vendedor ou fornecedor'" class="mt-1" v-model="request_sellers_filters.name" @keyup="filter_sellers(request_sellers, request_sellers_filters.name)" />
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-3 p-0 filter-controls">
								<cc-button @click="remove_all" :content="'Remover Todos'" :icon="'fa fa-trash'" :classes="'fixed danger-outline'" />
							</div>
						</div>
					</div>
					<div class="sellers-list list-group">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left px-3">Fornecedor</th>
									<th class="text-left px-0">Vendedor</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-show="seller.visible" v-for="seller in request_sellers" :key="seller.usu_id">
									<td class="text-left px-3"><b>{{ seller.for_nome }}</b></td>
									<td class="text-left px-0">{{ seller.usu_nome }}</td>
									<td>
										<i v-show="!seller.loading" class="fa fa-trash remove-btn" @click.stop="remove(seller)" />
										<cc-loader v-show="seller.loading" :show_txt="false" class="loader float-right" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-6">
					<label for="" class="label-control">Fornecedores disponíveis</label>
					<cc-loader v-show="is_loading_available_sellers"/>
					<div class="col-12 mb-3">
						<form @submit.prevent="">
							<div class="row">
								<div class="col p-0">
									<cc-search :placeholder="'Pesquisar vendedor ou fornecedor'" class="mt-1" v-model="filters.name" @keyup="filter_sellers(sellers, filters.name)" />
								</div>
								<div class="col p-0">
									<cc-select-v2 :placeholder="'Filtrar por grupo'" class="mt-1 mx-2" :tracker="'id'" :text="'nome'" :options="groups" v-model="filters.group"></cc-select-v2>
								</div>
							</div>
							<div class="row mt-3">
								<div class="col-3 p-0 filter-controls">
									<cc-button @click="add_all" :content="'Adicionar Todos'" :icon="'fa fa-plus'" :classes="'fixed success'" />
								</div>
							</div>
						</form>
					</div>
					<div class="sellers-list list-group">
						<table class="table">
							<thead>
								<tr>
									<th></th>
									<th class="text-left px-0">Fornecedor</th>
									<th class="text-left px-0">Vendedor</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="seller in sellers" :key="seller.usu_id" v-show="seller.visible">
									<td>
										<cc-loader v-show="seller.loading" :show_txt="false" class="loader float-left" />
										<i v-show="!seller.loading" class="fa fa-plus add-btn" @click.stop="add(seller)"/>
									</td>
									<td class="text-left px-0"><b>{{ seller.for_nome }}</b></td>
									<td class="text-left px-0">{{ seller.usu_nome }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</cc-modal> -->
</template>

<script>
import QuotationService from "@/services/QuotationService";
import SellersService from "@/services/v2/sellers.service";
import RequestsService from "@/services/v2/requests.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import Pagination from "@/components/cliente/base-components/Pagination";
import SellerGroupService from "@/services/v2/seller-group.service";
import { loaderMixin } from "@/mixins/sweet-loader.mixin";
import ProviderService from '@/services/v3/provider.service'
import InvitationService from '@/services/v3/invitation.service'

export default {
  mixins: [loaderMixin],
  props: {
    request: {
      type: Object,
      required: true,
    },
    closeDialog: {
      type: Function,
    },
  },
  components: {
    ccPagination: Pagination,
  },
  data() {
    return {
      newSuplier: false,
      invitation: {},
      sellers: [],
      request_sellers: [],
      filters: {
        name: "",
      },
      request_sellers_filters: {
        name: "",
      },
      loading: false,
      loading_general: false,
      loading_seller: true,
      loading_request_sellers: true,
      loading_group: true,
      total_items: 0,
      groups: [],
      is_loading_available_sellers: false,
      is_loading_request_sellers: false,
      service: new SellersService(),
      group_svc: new SellerGroupService(),
      req_svc: new RequestsService(),
      req_svc2: new QuotationService(),
      pro_svc: new ProviderService(),
      svc: new InvitationService(),
      modal: {
        title: `gerenciar fornecedores #${this.request.numeroCotacao}`,
        subtitle: `Gerencie os fornecedores que participam da sua compra/cotação`,
        icon_title: "fas fa-users",
        cancel_text: "Voltar",
        style: {
          width: "90%",
        },
      },
    };
  },
  methods: {
    save() {
      this.present_loader("Enviando convite...")
      this.svc.send(this.invitation).then(() => {
        if (this.invitation.invited_user_id) {
          this.req_svc2.enableSeller(this.invitation.invited_user_id).then(() => {
            this.add({ usu_id: this.invitation.invited_user_id })
            this.invitation.invited_user_id = {}
            this.$emit('load_sellers')
          })
        }
      }).catch((err) => {
        if (err.response && err.response.data.errors) {
          if (err.response.data.errors.some(text => text == "Convite já enviado para este número")) {
            if (this.invitation.invited_user_id) {
              this.req_svc2.enableSeller(this.invitation.invited_user_id).then(() => {
                this.add({ usu_id: this.invitation.invited_user_id })
                this.invitation.invited_user_id = {}
                this.$emit('load_sellers')
              })
            }
          }
        }
        this.dismiss_loader()
      })
    },
    search_by_phone() {
      return this.pro_svc.find_by_phone(this.invitation.receiver_phone)
        .then(response => response.data).then((data) => {
          this.invitation.receiver_name = data.usu_nome
          this.invitation.receiver_company_name = data.provider.for_nome
          this.invitation.invited_user_id = data.usu_id
          this.$set(this.invitation, 'lock', true)
        }).catch(error => {
          if (error.response.status == 404) {
            this.$store.dispatch('notification/add', {
              type: 'error',
              message: 'Desculpe, fornecedor não cadastrado.'
            })
          }
        })
    },
    getSpecialBackground(index) {
      if (index % 2 != 0) return 'page-table-line-special'
    },
    toggle_activation(seller, collection) {
      collection
        .filter((s) => s.usu_id !== seller.usu_id)
        .forEach((s) => (s.active = false));
      seller.active = !seller.active;
      this.$forceUpdate();
    },
    add(seller) {
      this.$set(seller, "loading", true);
      return this.req_svc
        .add_seller(this.request.numeroCotacao, {
          cot_id: this.request.numeroCotacao,
          vendedor_id: seller.usu_id,
        })
        .then(() => this.add_to_list(seller.usu_id))
        .then(() => {
          this.$set(seller, "loading", false)
          this.dismiss_loader()
        });
    },
    add_to_list(seller_id) {
      this.sellers
        .filter((ss) => ss.usu_id == seller_id)
        .forEach((s) => this.request_sellers.push(s));
      this.sellers = this.sellers.filter((s) => s.usu_id !== seller_id);
    },
    remove_from_list(seller_id) {
      this.request_sellers
        .filter((ss) => ss.usu_id == seller_id)
        .forEach((s) => this.sellers.push(s));
      this.request_sellers = this.request_sellers.filter((s) => s.usu_id !== seller_id);
    },
    remove(seller) {
      this.$set(seller, "loading", true);
      return this.req_svc
        .remove_seller(this.request.numeroCotacao, seller.usu_id)
        .then(() => {
          this.remove_from_list(seller.usu_id);
        })
        .then(() => this.$set(seller, "loading", false));
    },
    close() {
      this.$emit("close");
    },
    load_sellers() {
      this.loading_seller = true;
      return this.req_svc
        .sellers_out(this.request.numeroCotacao)
        .then((response) => response.data)
        .then((data) => {
          this.sellers = data;
          this.sellers.forEach((s) => (s.visible = true));
          this.filters.total_items = data.total;
          this.loading_seller = false;
          this.loading_general = !this.loading_general;
        })
        .catch((error) => {
          ErrorHandlerService.handle(error, this.$store);
        });
    },
    load_request_sellers() {
      this.loading_request_sellers = true;
      return this.req_svc
        .sellers_in(this.request.numeroCotacao)
        .then((response) => response.data)
        .then((data) => {
          this.request_sellers = data.data;
          this.request_sellers.forEach((s) => (s.visible = true));
          this.loading_request_sellers = false;
          this.loading_general = !this.loading_general;
        })
        .catch((error) => {
          ErrorHandlerService.handle(error, this.$store);
        });
    },
    filter_sellers(collection, filter = "") {
      collection.forEach((s) => (s.visible = false));
      collection
        .filter(
          (s) =>
            s.usu_nome.toLowerCase().includes(filter.toLowerCase()) ||
            s.for_nome.toLowerCase().includes(filter.toLowerCase())
        )
        .forEach((s) => (s.visible = true));
      this.$forceUpdate();
    },
    add_all() {
      this.confirm_action({
        message: "Confirma adição de todos os vendedores para essa compra/cotação?",
        subtitle:
          "Os fornecedores serão adicionados na compra/contação, você poderá remove-los posteriormente!",
        callback: () => {
          this.present_loader("Adicionando vendedores a compra/cotação...");
          return this.req_svc
            .add_sellers(
              this.request.numeroCotacao,
              this.sellers.map((s) => {
                return { vendedor_id: s.usu_id, cot_id: this.request.numeroCotacao };
              })
            )
            .then(() => this.sellers.forEach((s) => this.add_to_list(s.usu_id)))
            .then(() => this.dismiss_loader());
        },
      });
    },
    remove_all() {
      this.confirm_action({
        message: "Confirma remoção de todos os vendedores dessa compra/cotação?",
        subtitle:
          "Os fornecedores serão removidos da compra/contação, você poderá adiciona-los posteriormente!",
        callback: () => {
          this.present_loader("Removendo vendedores do compra/cotação...");
          return this.req_svc
            .remove_all_sellers(this.request.numeroCotacao)
            .then(() =>
              this.request_sellers.forEach((s) => this.remove_from_list(s.usu_id))
            )
            .then(() => this.dismiss_loader());
        },
      });
    },
    load_groups() {
      this.loading_group = true;
      return this.group_svc
        .all()
        .then((response) => response.data)
        .then((data) => {
          this.groups = data;
          this.loading_group = false;
          this.loading_general = !this.loading_general;
        })
        .catch((error) => {
          ErrorHandlerService.handle(error, this.$store);
        });
    },
    load_group(group) {
      this.loading = true;
      return this.group_svc
        .load(group.id)
        .then((response) => response.data)
        .then((data) => {
          this.toggle_activation(data, this.groups);
          this.sellers = data.sellers;
          this.sellers = this.sellers.map((s) => {
            return { ...s, ...s.provider };
          });
          this.sellers.forEach((s) => (s.visible = true));
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          ErrorHandlerService.handle(error, this.$store);
        });
    },
  },
  watch: {
    "filters.group": function (val) {
      if (val) {
        this.load_group(val);
      } else {
        this.load_sellers();
      }
    },
    loading_general: function () {
      if (this.loading_seller && this.loading_request_sellers && this.loading_group) {
        this.present_loader("Carregando dados...");
      }

      if (!this.loading_seller && !this.loading_request_sellers && !this.loading_group) {
        this.dismiss_loader();
      }
    },
  },
  mounted() {
    this.loading_general = !this.loading_general;
    this.load_sellers().then(this.load_request_sellers);
    this.load_groups();
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}

.modal-content-container {
  background-color: white;
  min-width: 50vw;
  border-radius: 12px;
}

.manage-sellers__main-container {
  width: 100vw;
  height: 105vh;
  display: flex;
  padding-bottom: 5vh;
  flex-direction: column;
}

.manage-sellers__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
  height: 12%;
  padding: 0 2vw;
}

.manage-sellers__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.8em;
}

.manage-sellers__closebtn-icon {
  color: #ffffff;
  font-size: 3em;
  cursor: pointer;
}

.manage-sellers__providers-container {
  display: flex;
  justify-content: space-between;
  margin: 1vh 2vw;
  height: 72%;
}

.manage-sellers__left-container {
  width: 45vw;
  border-radius: 8px;
  -webkit-box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.2);
}

.manage-sellers__left-wrapper {
  margin: 10px;
  height: 100%;
}

.invitation-modal__form-container {
  display: flex;
  gap: 2vw;
  align-items: end;
}

.manage-sellers__title-wrapper {
  margin-bottom: 1.6vh;
}

.manage-sellers__title {
  font-size: 1.7em;
  font-weight: 400;
  color: #707070;
}

.manage-sellers__input-wrapper {
  margin: 2vh 0px;
  display: flex;
  align-items: center;
}

.manage-sellers__input {
  width: 45vw;
  height: 5.5vh;
  padding: 0px 10px;
  border: 1px solid #c2c2c3;
  border-radius: 5.23px;
  font-weight: 400;
  font-size: 1.1em;
  color: #A1A1A1;
}

.manage-sellers__providers-container-top {
  display: flex;
  align-items: center;
  padding-left: 2vw;
  height: 15%;
}

.invitation-modal__input-title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}

.invitation-modal__input {
  padding: 10px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  width: 20vw;
}

.manage-sellers__input-icon {
  position: relative;
  font-size: 1.7em;
  left: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #A1A1A1;
}

.manage-sellers__actions-wrapper {
  display: flex;
  width: 18vw;
  align-items: center;
  padding: 1.5vh 1vw;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  background-color: #FAFAFC;
  cursor: pointer;
  justify-content: center;
}

.manage-sellers__actions-icon {
  color: var(--primary-color);
  margin-right: 1rem;
}

.manage-sellers__actions-title {
  font-weight: 400;
  font-size: 1.3em;
  color: #505050;
}

.manage-sellers__table-container {
  overflow-y: auto;
  height: 58%;
  margin-top: 2vh;
}

.manage-sellers__table-wrapper {}

.manage-sellers__table-thead {}

.manage-sellers__table-thead-tr {
  background-color: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  color: #605F5F;
  font-size: 1.1em;
  font-weight: 400;
}

.manage-sellers__table-th {
  font-size: 1.1em;
  font-weight: 600;
}

.manage-sellers__table-tbody {}

.manage-sellers__table-tbody-tr {}

.manage-sellers__table-td {
  color: #605F5F;
  font-size: 1.1em;
  font-weight: 300;
}

.delete-icon {
  color: var(--primary-color);
  cursor: pointer;
}

.add-icon {
  color: var(--primary-color);
  cursor: pointer;
}

.manage-sellers__right-container {
  width: 45vw;
  border-radius: 8px;
  -webkit-box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.2);
}

.manage-sellers__right-wrapper {
  margin: 10px;
  height: 100%;
}

.manage-sellers__right-actions__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2vh;
}

.manage-sellers__right-input__wrapper {
  margin: 8px 0px;
  display: flex;
  align-items: center;
  width: 28vw;
}

.manage-sellers__right-input {
  width: 40vw;
  height: 5.5vh;
  padding: 0px 10px;
  border: 1px solid #c2c2c3;
  border-radius: 5.23px;
  font-weight: 400;
  font-size: 1.1em;
  color: #A1A1A1;
}

.manage-sellers__right-filter__wrapper {}

.manage-sellers__right-filter {
  width: 15vw !important;
  border: none !important;
}

.page-table-line-special {
  background: #F7F7F7;
}

@media only screen and (max-width: 800px) {
  .modal-mask {
    font-size: 2vw;
  }

  .invitation-modal__input-wrapper {
    margin: 15px 0px;
  }

  .manage-sellers__providers-container {
    flex-direction: column;
  }

  .manage-sellers__left-container {
    width: 90vw;
    height: 40vh;
    overflow-x: auto;
    overflow-y: auto;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .manage-sellers__right-container {
    width: 90vw;
    height: 40vh;
    overflow-x: auto;
    overflow-y: auto;
    margin-left: 20px;
  }

  .manage-sellers__right-actions__wrapper {
    flex-direction: column;
  }

  .manage-sellers__actions-wrapper {
    width: 52vw;
  }

  .manage-sellers__right-input__wrapper {
    width: 80vw;
    margin-left: 20px;
  }

  .manage-sellers__right-input {
    width: 75vw;
  }

  .manage-sellers__right-filter__wrapper {}

  .manage-sellers__right-filter {
    width: 75vw !important;
  }

  .manage-sellers__input-icon {
    display: none;
  }

  .manage-sellers__input {
    width: 90vw;
  }
}

@media only screen and (max-width: 500px) {
  .modal-mask {
    font-size: 3vw;
  }

  .manage-sellers__right-container {
    height: 30vh
  }
}

@import "./manage-request-sellers.modal";
</style>
