<template>
  <div class="modal-mask">
    <cc-loader-full v-if="isLoadingFull" />
    <div class="modal-content-container">
      <div class="products-brands__main-container">
        <div class="products-brands__header-container">
          <span class="products-brands__header-title">
            {{ this.product.pro_descricao }}
          </span>
          <span
            @click="closeDialog"
            class="material-icons products-brands__closebtn-icon"
          >
            cancel
          </span>
        </div>
        <div
          v-if="actionSearch"
          class="products-brands__search-brands__container"
        >
          <!-- <div class="products-brands__search-brands__title-wrapper">
            <span class="products-brands__search-brands__add-title">
              Marcas #{{ this.product.pro_descricao }}
            </span>
          </div> -->
          <div class="products-brands__search-brands__search-wrapper">
            <span class="products-brands__search-brands__search-title">
              Buscar por marca
            </span>
          </div>
          <div class="products-brands__search-brands__input-wrapper">
            <div class="products-brands__search-brands__input-container">
              <input
                placeholder="Digite a marca"
                v-model="filters.name"
                class="products-brands__search-brands__search-input"
                type="text"
              />
              <span
                class="material-icons products-brands__search-brands__search-icon"
              >
                search
              </span>
            </div>
            <div
              class="products-brands__actions-container"
              v-if="can('PRODUCT', 'UPDATE')"
              :disabled="!actionSearch"
              @click="actionSearch = !actionSearch"
            >
              <div class="products-brands__actions__wrapper">
                <span class="material-icons products-brands__actions-icon">
                  add_circle_outline
                </span>
                <span class="products-brands__actions-title">
                  Adicionar Marca
                </span>
              </div>
            </div>
            <div class="products-brands__actions-container" @click="onlyActivated = !onlyActivated">
              <div class="products-brands__actions__wrapper">
                <span v-if="!onlyActivated" class="material-icons products-brands__actions-icon">
                  radio_button_unchecked
                </span>
                <span v-else class="material-icons products-brands__actions-icon">
                  radio_button_checked
                </span>
                <span class="products-brands__actions-title">
                  Apenas Produtos ativos
                </span>
              </div>
            </div>
          </div>
        </div>
        <cc-loader v-if="loading" style="margin-top: 15vh" />
        <div
          v-if="actionSearch && !loading"
          class="products-brands__table-container"
        >
          <table class="products-brands__table-wrapper">
            <thead class="products-brands__table-thead">
              <tr class="products-brands__table-thead-tr">
                <th class="products-brands__table-th">Marca</th>
                <th class="products-brands__table-th">Cod. interno</th>
                <th class="products-brands__table-th text-center">
                  Preferêncial
                </th>
                <th class="products-brands__table-th text-center">
                  Valor Ulti. Compra
                </th>
                <th class="products-brands__table-th text-center">Ativo</th>
              </tr>
            </thead>
            <tbody class="ressuply-products__table-tbody">
              <tr
                v-for="(data, index) in filtred_brands"
                :key="index"
                :class="getSpecialBackground(index)"
              >
                <td class="products-brands__table-td">
                  {{ data.pro_observacao || '--' }}
                </td>
                <td class="products-brands__table-td">
                  {{ data.pro_codInterno ? data.pro_codInterno : '--' }}
                </td>
                <td class="products-brands__table-td text-center">
                  <i
                    style="padding-left: 1% !important"
                    @click="tooglePreferencialMarcas(data)"
                    class="fa fa-star fa-lg pointer"
                    :class="[
                      { iconStarGold: data.pro_preferencial == 1 },
                      { iconStarGray: data.pro_preferencial == 0 },
                    ]"
                    aria-hidden="true"
                  ></i>
                </td>
                <td class="products-brands__table-td text-center">
                  {{ data.pro_ultimaCompra | currency }}
                </td>
                <td class="products-brands__table-td text-center">
                  <span
                    v-if="data.pro_status == '1'"
                    v-on:click="can('PRODUCT', 'UPDATE') && updateProductStatus(data)"
                    style="cursor: pointer; color: var(--primary-color)"
                    class="material-icons-outlined"
                    >verified</span
                  >
                  <span
                    v-else
                    v-on:click="can('PRODUCT', 'UPDATE') && updateProductStatus(data)"
                    style="cursor: pointer"
                    class="material-icons-outlined"
                    >radio_button_unchecked</span
                  >
                  <span style="cursor: pointer; color: lightgreen" class="material-icons-outlined" v-on:click="showProductHistory=data">format_list_bulleted</span>
                  <span v-if="can('PRODUCT', 'UPDATE')" @click="editBrand=data" style="cursor: pointer; color: lightblue" class="material-icons-outlined">edit</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="!actionSearch"
          @click="actionSearch = !actionSearch"
          class="products-brands__back-btn"
        >
          <span class="material-icons products-brands__actions-icon">
            arrow_back
          </span>
          <span class="products-brands__actions-title"> Voltar </span>
        </div>
        <div style="margin: 20px 0px" slot="body" class="row">
          <div class="container-fluid" v-if="!actionSearch">
            <div class="col-12 pb-3">
              <div class="row">
                <div class="col-12">
                  <cc-search
                    :placeholder="'Nova Marca'"
                    :icon="'fa success fa-plus'"
                    class="mt-1"
                    v-model="new_brand"
                    @search="save()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div slot="body" class="row">
            <div class="container-fluid" v-if="actionSearch">
                <div class="col-12 pb-3">
                    <div class="row">
                        <div class="col-9">
                            <cc-search :placeholder="'Buscar marca'" class="mt-1" v-model="filters.name" />
                        </div>
                        <div class="col-3" v-if="can('CREATE', 'PRODUCT')">
                            <cc-button-new :disabled="!actionSearch" :content="'Adicionar Marca'"   @click="actionSearch = !actionSearch" />
                        </div>
                    </div>
                </div>

                <div class="col-12  p-3">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="pl-3">Marca</th>
                                <th class="text-center">Preferencial</th>
                                <th class="text-center">Valor Últ.Compra</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data,index) in filtred_brands" :key="index">
                                <td>{{ data.pro_observacao }}</td>
                                <td class="text-center"><i style="padding-left: 1% !important" @click="tooglePreferencialMarcas(data)" class="fa fa-star fa-lg pointer" :class="[{iconStarGold: data.pro_preferencial == 1},{iconStarGray: data.pro_preferencial == 0}]" aria-hidden="true"></i></td>
                                <td class="text-center">{{ data.pro_ultimaCompra | currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
        <!-- NÃO EDITAR -->
        <!-- <div slot="body" class="row">
            <div class="container-fluid" v-if="!actionSearch">
                <div class="col-12 pb-3">
                    <div class="row">

                        <div class="col-12">
                            <cc-search :placeholder="'Nova Marca'" :icon="'fa success fa-plus'" :theme="'theme-green'" class="mt-1" v-model="new_brand" @search="save()" /> -->
        <!-- <div class="msg-return" v-if="erro_brand"><label class="text-danger">Não foi possível salvar a marca</label></div>
                            <div class="msg-return" v-if="sucess_brand"><label class="text-success">Marca Cadastrada com sucesso</label></div> -->
        <!--                         </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <cc-modal :modal="modal" @close="close">
        <div slot="body" class="row">
            <div class="container-fluid" v-if="actionSearch">
                <div class="col-12 pb-3">
                    <div class="row">
                        <div>TEST</div>
                        <div class="col-9">
                            <cc-search :placeholder="'Buscar marca'" class="mt-1" v-model="filters.name" />
                        </div>
                        <div class="col-3" v-if="can('CREATE', 'PRODUCT')">
                            <cc-button-new :disabled="!actionSearch" :content="'Adicionar Marca'"   @click="actionSearch = !actionSearch" />
                        </div>
                    </div>
                </div>

                <div class="col-12  p-3">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="pl-3">Marca</th>
                                <th class="text-center">Preferencial</th>
                                <th class="text-center">Valor Últ.Compra</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data,index) in filtred_brands" :key="index">
                                <td>{{ data.pro_observacao }}</td>
                                <td class="text-center"><i style="padding-left: 1% !important" @click="tooglePreferencialMarcas(data)" class="fa fa-star fa-lg pointer" :class="[{iconStarGold: data.pro_preferencial == 1},{iconStarGray: data.pro_preferencial == 0}]" aria-hidden="true"></i></td>
                                <td class="text-center">{{ data.pro_ultimaCompra | currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
        <!-- NÃO EDITAR -->
        <!-- <div slot="body" class="row">
            <div class="container-fluid" v-if="!actionSearch">
                <div class="col-12 pb-3">
                    <div class="row">

                        <div class="col-12">
                            <cc-search :placeholder="'Nova Marca'" :icon="'fa success fa-plus'" :theme="'theme-green'" class="mt-1" v-model="new_brand" @search="save()" /> -->
        <!-- <div class="msg-return" v-if="erro_brand"><label class="text-danger">Não foi possível salvar a marca</label></div>
                            <div class="msg-return" v-if="sucess_brand"><label class="text-success">Marca Cadastrada com sucesso</label></div> -->
        <!--                </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- </cc-modal> -->
      </div>
    </div>
    <ProductHistory v-if="showProductHistory" :product="showProductHistory" :close="()=>showProductHistory=false"/>
    <EditBrandModal v-if="editBrand" :brand="editBrand" :update="update" :close="()=>editBrand=false"/>
  </div>
</template>

<script>
import * as CONSTANTS from "@/constants/constants";
import ProductService from "@/services/ProductService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { perm_mixin } from "@/mixins/permission.mixin";
import ProductHistory from "@/pages/products/ProductsLog.vue";
import EditBrandModal from "./EditBrandModal.vue";


export default {
  mixins: [perm_mixin],
  props: {
    brands: {
      type: Array,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    closeDialog: {
      type: Function,
      required: false,
    },
  },
  components:{
    ProductHistory,
    EditBrandModal
  },
  data() {
    return {
      prod_svc: new ProductService(),
      modal: {
        title: `Marcas #${this.product.pro_descricao}`,
        icon_title: "fa fa-list",
        cancel_text: "Voltar",
        subtitle: "Acompanhe as marcas disponíveis para seus produtos",
        style: {
          width: "65%",
        },
      },
      filters: {
        name: "",
      },
      editBrand: undefined,
      actionSearch: true,
      new_brand: null,
      sucess_brand: false,
      erro_brand: false,
      brands_modal: [],
      loading: true,
      isLoadingFull: false,
      showProductHistory: false,
      onlyActivated: true
    };
  },
  methods: {
    updateProductStatus(data) {
      this.isLoadingFull = true;
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      let payload = { pro_id: data.pro_id };
      if (data.pro_status == "1") payload.pro_status = "0";
      else payload.pro_status = "1";
      let url = `${CONSTANTS.API_URL}/atualizarprodutonormalcliente/ativar`;
      axios
        .put(url, payload, config)
        .then((res) => {
          data.pro_status = res.data.pro_status;
          this.isLoadingFull = false;
        })
        .catch(() => {
          this.isLoadingFull = false;
        });
    },
    close() {
      if (this.actionSearch) {
        this.$emit("close");
      } else {
        this.actionSearch = !this.actionSearch;
      }
    },
    tooglePreferencialMarcas(brand) {
      brand.pro_preferencial = brand.pro_preferencial === 0 ? 1 : 0;
      const data = {
        idProduto: brand.pro_id,
        preferencial: brand.pro_preferencial,
      };
      this.prod_svc.pin_brand(data);
    },
    toogleAtivoMarcas(index) {
      this.brands[index].pro_status = +!this.brands[index].pro_status;

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          produto_id: this.brands[index].pro_id,
          status: this.brands[index].pro_status,
        },
      };
      let url = `${CONSTANTS.API_URL}/inativarativarprodutocliente`;
      axios.post(url, {}, config).then(() => {});
    },
    update(){
      this.editBrand = false;
      this.isLoadingFull = true;
      this.loadBrands()
    },
    save() {
      const data = {
        produto: this.product.pro_id,
        marca: this.new_brand,
      };

      this.prod_svc
        .save_brand(data)
        .then((data) => {
          this.new_brand = null;
          this.loadBrands();
          this.actionSearch = true;
          //this.showMessageSucess();
          this.$swal.fire(
            "Sucesso!",
            "Nova marca adicionada com sucesso.",
            "success"
          );
        })
        .catch((error) => {
          this.showMessageErro();
        });
    },
    loadBrands() {
      this.prod_svc
        .get_product_brands(this.product.pro_id)
        .then((data) => {
          this.brands_modal = data.data.produtos.sort((a, b) => a.pro_observacao.localeCompare(b.pro_observacao));
          this.isLoadingFull = false
        })
        .catch((error) => {
          this.isLoadingFull = false
          ErrorHandlerService.handle(error, this.$store);
        });
    },
    showMessageSucess() {
      const notification = {
        type: "sucess",
        message: "Marca Cadastrada com sucesso!",
      };
      this.$store.dispatch("notification/add", notification);
    },
    showMessageErro() {
      const notification = {
        type: "error",
        message: "Não foi possível salvar a marca!",
      };
      this.$store.dispatch("notification/add", notification);
    },
    getSpecialBackground(idx) {
      if (idx % 2 != 0) return "page-table-line-special";
    },
  },
  mounted() {
    document.documentElement.style.overflow = "hidden";
    this.brands_modal = this.brands.sort((a, b) => a.pro_observacao && b.pro_observacao ? a.pro_observacao.localeCompare(b.pro_observacao) : 1);
    this.loading = false;
  },
  destroyed() {
    document.documentElement.style.overflow = "auto";
  },
  computed: {
    filtred_brands() {
      let resp = this.brands_modal
      if (this.onlyActivated) resp = resp.filter(value => value.pro_status == '1')
      if (this.filters.name) resp = resp.filter((marca) => marca.pro_observacao.toLowerCase().includes(this.filters.name.toLowerCase()))
      return resp
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./product-brands.modal";
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container {
  background-color: white;
  min-width: 70vw;
  border-radius: 12px;
  padding-bottom: 3vh;
  margin-top: 40px;
}
.products-brands__main-container {
  width: 70vw;
  height: 90vh;
}
.products-brands__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.products-brands__header-title {
  color: #ffffff;
  letter-spacing: -2%;
  font-weight: 500;
  font-size: 32px;
}
.products-brands__closebtn-icon {
  color: #FFFFFF;
  font-size: 3.5em;
  cursor: pointer;
}
.products-brands__search-brands__container {
  margin: 20px;
}
.products-brands__search-brands__title-wrapper {
  margin-bottom: 20px;
}
.products-brands__search-brands__add-title {
  color: #606060;
  font-size: 24px;
  font-weight: 400;
}
.products-brands__search-brands__search-wrapper {
}
.products-brands__search-brands__search-title {
  color: #898989;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin-bottom: 10px;
}
.products-brands__search-brands__input-wrapper {
  display: flex;
  align-items: center;
}
.products-brands__search-brands__input-container {
  display: flex;
  align-items: center;
}
.products-brands__search-brands__search-input {
  width: 25vw;
  color: #a1a1a1;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 12px;
}
.products-brands__search-brands__search-icon {
  position: relative;
  color: #898989;
  font-size: 26px;
  left: -40px;
  cursor: pointer;
}
.products-brands__actions-container {
  display: flex;
  align-items: center;
}
.products-brands__actions__wrapper {
  display: flex;
  align-items: center;
  padding: 12px;
  margin: 0px 10px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  background-color: #fafafc;
  cursor: pointer;
}
.products-brands__back-btn {
  display: flex;
  width: 8vw;
  align-items: center;
  padding: 12px;
  margin: 20px 30px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  background-color: #fafafc;
  cursor: pointer;
}
.products-brands__actions-icon {
  color: var(--primary-color);
  margin-right: 1rem;
}
.products-brands__actions-title {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2%;
  color: #505050;
}
.products-brands__table-container {
  margin: 20px;
  overflow-y: auto;
  max-height: 60vh;
}
.products-brands__table-wrapper {
}
.products-brands__table-thead {
}
.products-brands__table-thead-tr {
  background-color: #fffefc;
  border: 0.5px solid #e5e5e5;
  color: #605f5f;
  font-size: 14px;
  font-weight: 400;
}
.products-brands__table-th {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
}
.products-brands__table-td {
  font-family: Rubik;
  color: #605f5f;
  font-size: 14px;
  font-weight: 300;
}
.page-table-line-special {
  background: #f7f7f7;
}
@media only screen and (max-width: 750px) {
  .modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
  }
  .modal-content-container {
    background-color: white;
    min-width: 70vw;
    border-radius: 12px;
    padding-bottom: 3vh;
    margin-top: 40px;
  }
  .products-brands__main-container {
    width: 70vw;
    height: 90vh;
  }

  .products-brands__header-title {
    font-size: 20px;
  }
  .products-brands__search-brands__add-title {
    font-size: 16px;
  }
  .products-brands__header-container {
    padding: 10px;
  }
  .products-brands__search-brands__input-wrapper {
  }
  .products-brands__search-brands__input-container {
    width: 55%;
  }
  .products-brands__actions__wrapper {
    margin: 0;
  }
  .products-brands__search-brands__search-input {
    margin: 10px 0;
    width: 60vw;
  }
  .products-brands__main-container {
    height: 100vh;
    width: 120vw;
  }
  .products-brands__search-brands__container {
    margin: 20px;
  }
  .products-brands__table-container {
    margin: 20px;
  }
  .products-brands__back-btn {
    width: 40vw;
    margin: 20px auto;
  }
}
</style>
